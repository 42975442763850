import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import PageHeader from '../../Components/PageHeader';
import { getGeneratedImage } from '../../../redux/actions/connectActions';
import { FILE_URL } from '../../../redux/env';

const GeneratedImage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state?.id;
    const [loading, setLoading] = useState(true);

    const getImageDetails = useSelector(state => state.generatedImage.generatedImage);
    console.log('getImageDetails', getImageDetails);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getGeneratedImage(id));
            } catch (error) {
                console.error('Failed to fetch rewritten content', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]); 

    const downloadImage = (imgurl) => {
        const link = document.createElement('a');
        link.href = imgurl;
        link.setAttribute('download', 'generated_image.png'); // Ensures download with the specified filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after download
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!getImageDetails) {
        return <div>No content found</div>;
    }

    return (
        <main className="p-4 min-h-screen">
            <PageHeader title="Generated Image" subtitle="Generate professional quality images from text for your website or blog." />
            <ToastContainer />

            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="prose mb-6">
                     {getImageDetails && (
                        <>
                            <p className="text-xl font-semibold text-gray-500 dark:text-white md:text-xl">{getImageDetails.description}</p>
                            <img src={FILE_URL + getImageDetails.image_file} alt="Generated Image" className="w-full h-auto" />
                        </>
                    )}
                </div>
                <div className="flex justify-center space-x-4 mt-6">
                     {getImageDetails && (
                    <button
                        onClick={() => downloadImage(FILE_URL + getImageDetails.image_file)}
                        className="px-2 md:px-4 py-2 text-primary-600 bg-white font-semibold border border-primary-600 rounded-full bg-gradient-to-r hover:from-primary-600 hover:to-primary-800 hover:text-white"
                    >
                        Download Image
                    </button>
                    )}
                    <button
                        onClick={() => navigate(-1)}
                        className="px-2 md:px-4 py-2 text-primary-600 bg-white font-semibold border border-primary-600 rounded-full bg-gradient-to-r hover:from-primary-600 hover:to-primary-800 hover:text-white"
                    >
                        Go Back
                    </button>
                </div>
            </div> 
        </main>
    );
};

export default GeneratedImage;
