import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="bg-primary-50 py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center md:space-x-10">
          <div className="flex space-x-6 text-gray-700 text-lg font-medium">
          <Link className="md:hidden hover:text-primary-600 transition" to="/home">Home</Link>
          <Link className="md:hidden hover:text-primary-600 transition" to="/contact">Contact</Link>
            <Link className="hidden md:block hover:text-primary-600 transition" to="/article-generator">Article Generator</Link>
            <Link className="hidden md:block hover:text-primary-600 transition" to="/contblog-post-generatoract">Blog Post Writer</Link>
            <Link className="hidden md:block hover:text-primary-600 transition" to="/rewrite-conetent-generator">Content Rewriter</Link>
            <Link className="hidden md:block hover:text-primary-600 transition" to="/image-generator">Image Generator</Link>
            <Link className="hidden md:block hover:text-primary-600 transition" to="/social-media-post-generator">Social Media Post</Link>
          </div>
          <div className="flex space-x-6 mt-6 md:mt-0">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-primary-600 transition">
              <FaFacebookF size={24} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-primary-600 transition">
              <FaLinkedinIn size={24} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-primary-600 transition">
              <FaInstagram size={24} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-primary-600 transition">
              <FaTwitter size={24} />
            </a>
            <a href="mailto:info@connectaibox.com" className="text-gray-700 hover:text-primary-600 transition">
              <FaEnvelope size={24} />
            </a>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-200 pt-6 text-center">
          <p className="text-gray-600">&copy; {currentYear} Connect AI Box. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
