import React, { useState } from 'react';
import { FaHome, FaCalendarAlt, FaUser, FaCog, FaChartLine, FaFileInvoice, FaUsers, FaTimes } from 'react-icons/fa';
import { FaRegImage } from 'react-icons/fa';
import { MdContentPaste } from "react-icons/md";
import { AiOutlineRetweet } from 'react-icons/ai';
import { GrBlog } from "react-icons/gr";
import { IoCartOutline, IoShareSocialOutline } from "react-icons/io5";
import { IoHomeOutline, IoDocumentsOutline } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";
import { MdDocumentScanner } from "react-icons/md";
import { TbLogout } from "react-icons/tb";

import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../Common/AuthProvider';

const SideBar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { logout } = useAuth();

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'bg-primary-600 text-white' : '';
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1024) { // adjust the width as per your breakpoint
      setSidebarOpen(false);
    }
  };

  return (
    <div>
      <aside id="default-sidebar" className={`fixed px-4 top-0 left-0 z-40 inset-y-0 left-0 bg-white h-screen shadow-lg w-64 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:translate-x-0 lg:inset-0`}>
        <div className="flex items-center justify-between p-4">
          <NavLink to="/" className="flex items-center" onClick={handleLinkClick}>
            <img src='/images/logo.png' alt="Logo" className="h-8" />
            <span className="self-center text-2xl ml-2 text-gray-800 font-extrabold whitespace-nowrap dark:text-white">
              Connect.AI
            </span>
          </NavLink>
          <button className="lg:hidden focus:outline-none" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <FaTimes />
          </button>
        </div>
        <nav className="flex flex-col justify-between h-full">
          <div className='mt-4 lg:mt-8'>
            <NavLink to="/dashboard" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/dashboard')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <IoHomeOutline size="20" className="mr-3" /> Dashboard
            </NavLink>
            <NavLink to="/articles" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/articles')} ${activeLink('/article-generator')} ${activeLink('/generated-article')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <MdContentPaste size="20" className="mr-3" /> Articles
            </NavLink>
            <NavLink to="/blogs" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/blog-post-generator')} ${activeLink('/blogs')} ${activeLink('/generated-blog')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <GrBlog size="19" className="mr-3" /> Blogs
            </NavLink>    
            <NavLink to="/rewrite-contents" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/rewrite-conetent-generator')} ${activeLink('/rewrite-contents')} ${activeLink('/generated-rewrite-content')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <AiOutlineRetweet size="20" className="mr-3" /> Rewrite
            </NavLink>    
            <NavLink to="/generated-images" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/image-generator')} ${activeLink('/generated-images')} ${activeLink('/generated-image')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <FaRegImage size="18" className="mr-3" /> Generate Image
            </NavLink>    
            <NavLink to="/product-descriptions" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/product-description-generator')} ${activeLink('/product-descriptions')} ${activeLink('/generated-product-description')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <IoCartOutline size="20" className="mr-3" /> Descriptions
            </NavLink>    
            <NavLink to="/social-media-posts" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 font-normal text-base/[27px] ${activeLink('/social-media-post-generator')} ${activeLink('/social-media-posts')} ${activeLink('/generated-social-media-post')} hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <IoShareSocialOutline size="20" className="mr-3" /> Media Posts
            </NavLink>    

          </div>
          <div className="mb-16">
            <NavLink to="/profile" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500  text-base/[27px]  hover:bg-gray-200 transition-colors duration-300`} onClick={handleLinkClick}>
              <FaCog className="mr-3" /> Settings
            </NavLink>
            <NavLink onClick={() => logout()} className={`flex bg-primary-200  mt-1 items-center py-2 px-6 rounded mb-3 text-primary-600 font-semibold text-base/[27px] hover:bg-gray-200 transition-colors duration-300`}>
              <TbLogout size={20} className="font-bold mr-3" /> Logout
            </NavLink>
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default SideBar;
