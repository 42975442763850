import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducers/authReducers';
import { loadingReducer } from './reducers/loadingReducer';
import { getArticleReducer, getBlogReducer, getGeneratedImageReducer, getProductDescriptionReducer, getRewrittenContentReducer, getSocialMediaPostReducer } from './reducers/connectReducers';

const rootReducer = combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    article: getArticleReducer,
    blog: getBlogReducer,   
    rewrittenContent: getRewrittenContentReducer,
    productDescription: getProductDescriptionReducer,
    socialMediaPost: getSocialMediaPostReducer,
    generatedImage: getGeneratedImageReducer,
});

const initialState = {
    auth: { userInfo: null },
    article: {},
    blog: {},
    rewrittenContent: {},
    productDescription: {},
    socialMediaPost: {},
    generatedImage: {},
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

export default store;
