import React from 'react';
import { Link } from 'react-router-dom';

const DashboardCard = ({ title, description, icon, link }) => {
  return (
    <Link to={link} className="block rounded-lg border border-stroke bg-white p-6 shadow-lg dark:border-strokedark dark:bg-boxdark md:p-8 xl:p-9 relative overflow-hidden transition-transform transform hover:scale-105">
      <div className="absolute inset-0 bg-gradient-to-r from-primary-50 to-white opacity-50 z-0"></div>
      <div className="flex items-center justify-between mb-4 relative z-10">
        <div className="bg-primary-100 p-2 rounded-full">
          {icon}
        </div>
      </div>
      <div className="space-y-3 relative z-10">
        <h4 className="font-medium text-gray-700 dark:text-gray-300">{title}</h4>
        <p className="text-sm text-gray-600 dark:text-gray-400">{description}</p>
      </div>
      <div className="absolute bottom-0 right-0 w-24 h-24 bg-gradient-to-br from-primary-300 to-primary-500 opacity-30 rounded-full transform translate-x-1/2 translate-y-1/2 z-0"></div>
    </Link>
  );
};

const DashboardCards = ({ data }) => {
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-6 2xl:gap-7.5">
      {data && data.map((item, index) => (
        <DashboardCard
          key={index}
          title={item.title}
          description={item.description}
          icon={item.icon}
          link={item.link}
        />
      ))}
    </div>
  );
};

export default DashboardCards;
