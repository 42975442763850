import React from 'react';

const FeaturesSection = () => { 

  return (
    <section id="features" aria-label="Features for running your books" className="bg-gradient-to-r from-primary-100 to-fuchsia-100 relative overflow-hidden bg-primary-600 pb-28 pt-20 sm:py-22"> 
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="max-w-1xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight font-bold text-gray-600 sm:text-4xl md:text-4xl">Everything you need to manage your content.</h2>
          <p className="w-full md:max-w-3xl mx-auto mt-6 text-md font-semibold tracking-tight text-gray-500">Streamline your content creation process with our comprehensive features, designed to enhance efficiency, improve collaboration, and ensure your content reaches its full potential.</p>
        </div>
        <div className="mt-4 md:mt-16 items-center gap-y-2 pt-4 md:pt-10 sm:gap-y-6 md:mt-20 lg:pt-0">
          <img alt="" fetchpriority="high" decoding="async" className="w-full md:max-w-5xl mx-auto shadow-lg rounded-xl" style={{ color: 'transparent' }} src="./images/connectai-dashboard.png" /> 
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
