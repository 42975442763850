import React from 'react';
import HomePageLayout from './Home/Homelayout';
import FeaturesSection from './Home/Featuresection';
import TestimonialsSection from './Home/Testimonialsection';
import HomePageHero from './Home/HomePageHero';
import CallToAction from './Home/CallToAction';
import HomeFaq from './Home/HomeFaq';

const Home = () => {
    return (
        <div> 
            <HomePageHero />
            <HomePageLayout />
            <FeaturesSection />
            <TestimonialsSection />
            <HomeFaq />
            <CallToAction />
        </div>
    );
};

export default Home;
