import React from 'react';

const StepNavigation = ({ currentStep, steps }) => {
  return (
    <div className="flex flex-wrap bg-gray-200 rounded-t-lg overflow-x-auto md:overflow-x-hidden">
      {steps.map((step, index) => (
        <div key={index} className="py-2 px-4 flex items-center space-x-2">
          <div
            className={`flex items-center justify-center w-10 h-10 rounded-full ${
              currentStep === index + 1
                ? 'bg-primary-500 text-white shadow-lg'
                : 'bg-gray-300 text-gray-700'
            } transition duration-500 ease-in-out transform ${
              currentStep === index + 1 ? 'scale-110' : 'scale-100'
            }`}
          >
            {index + 1}
          </div>
          <div className="absolute w-32 text-center">
            <div
              className={`text-sm font-semibold transition duration-500 ease-in-out ${
                currentStep === index + 1 ? 'text-primary-500' : 'text-gray-500'
              }`}
            >
              {step}
            </div>
          </div>
          {index < steps.length - 1 && (
            <div
              className={`w-8 h-1 transition duration-500 ease-in-out`}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepNavigation;
