import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import PageHeader from '../../Components/PageHeader';
import { getSocialMediaPost, updateSocialMediaPost } from '../../../redux/actions/connectActions';

const SocialMediaPost = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state?.id;
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [postContents, setPostContents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const getSocialMediaPostDetails = useSelector(state => state.socialMediaPost.socialMediaPost);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getSocialMediaPost(id));
            } catch (error) {
                console.error('Failed to fetch social media post', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);
    
    useEffect(() => {
        if (getSocialMediaPostDetails) {
            const parsedContent = getSocialMediaPostDetails.generated_content ? JSON.parse(getSocialMediaPostDetails.generated_content) : [];
            setPostContents(parsedContent);
        }
    }, [getSocialMediaPostDetails]);

    const handleInputChange = (index, field, value) => {
        const updatedPostContents = [...postContents];

        // Character validation: Limit the post content to 500 characters
        if (value.length > 1000) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [index]: 'Post content must be less than 500 characters.',
            }));
            return;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                [index]: '', // Clear any previous error
            }));
        }

        updatedPostContents[index][field] = value;
        setPostContents(updatedPostContents);
    };

    const handleUpdateSocialMediaPost = async () => {
        try {
            setUpdating(true);
            const formData = {
                generated_content: JSON.stringify(postContents),
            };
            await dispatch(updateSocialMediaPost(id, formData));
            toast.success('Social media post updated successfully');
        } catch (error) {
            console.error('Failed to update social media post', error);
            toast.error('Failed to update social media post');
        } finally {
            setUpdating(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!getSocialMediaPostDetails) {
        return <div>No social media post found</div>;
    }

    return (
        <main className="p-4 min-h-screen">
            <PageHeader title="Social Media Post Generator" subtitle="Edit your social media post or write your own" />
            <ToastContainer />
            <div className="p-6 bg-white rounded-lg shadow-md">
                {postContents.map((postContent, index) => (
                    <div key={index} className="mb-4">
                        <label className="block font-semibold text-gray-700 mb-2">Post {index + 1}</label>
                        <textarea
                            value={postContent.post}
                            onChange={(e) => handleInputChange(index, 'post', e.target.value)}
                            className="w-full px-4 py-2 text-gray-600 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400"
                            rows="4"
                        />
                        {errors[index] && <p className="text-red-500 text-sm mt-2">{errors[index]}</p>}
                    </div>
                ))}
                <div className="flex justify-center space-x-4 mt-6"> 
                    <button
                        onClick={handleUpdateSocialMediaPost}
                        className={`px-4 py-2 rounded-full font-semibold border border-primary-600 text-white ${updating ? 'bg-gradient-to-r from-gray-500 to-gray-700' : 'bg-gradient-to-r from-primary-500 to-primary-700 hover:from-primary-600 hover:to-primary-800'}`}
                        disabled={updating}
                    >
                        {updating ? 'Updating...' : 'Update Post'}
                    </button>
                </div>
            </div> 
        </main>
    );
};

export default SocialMediaPost;