import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaSearch, FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getGeneratedImages } from '../../../redux/actions/connectActions';

const GeneratedImages = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const itemsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(getGeneratedImages());
                setImages(data);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]); 

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const filteredImages = Array.isArray(images) ? images.filter((image) =>
        (image.description && image.description.toLowerCase().includes(filter.toLowerCase())) ||
        (image.created_at && image.created_at.includes(filter))
    ) : [];

    const offset = currentPage * itemsPerPage;
    const currentImages = filteredImages.slice(offset, offset + itemsPerPage);

    const handleView = (imgId) => {
        navigate('/generated-image', { state: { id: imgId } });
    };

    return (
        <div>
            <main className="p-4 min-h-screen">
                <div className="grid grid-cols-1 mt-6 mb-6">
                    <h1 className="text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Generated Image List</h1>
                    <p className="text-gray-500 dark:text-gray-300">
                        List of created images!
                    </p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                    <div className="flex justify-between items-center mb-6">
                        <div className="relative w-1/3">
                            <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
                            <input
                                type="text"
                                placeholder="Filter images"
                                className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        <Link to="/image-generator" className="flex items-center px-2 md:px-8 py-2 text-white bg-primary-600 rounded-md shadow-md hover:from-primary-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                            <FaFileAlt className="mr-2" />
                            Generate Image
                        </Link>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg">
                            <thead className="bg-gradient-to-r from-primary-100 to-indigo-100">
                                <tr>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Description</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Image</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentImages.length > 0 ? (
                                    currentImages.map((image, index) => (
                                        <tr key={index} className="border-b hover:bg-gray-50">
                                            <td className="py-3 px-5 text-sm text-gray-800">
                                                <button onClick={() => handleView(image.id)} className='text-primary-700 font-semibold'>
                                                    {image.description.length > 50 ? `${image.description.slice(0, 50)}...` : image.description}
                                                </button>
                                            </td>

                                            <td className="py-3 px-5 text-sm text-gray-800">{formatDate(image.created_at)}</td>
                                            
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="py-10 text-center">
                                            <div className="flex flex-col items-center">
                                                <FaFileAlt className="mb-3 text-6xl text-gray-400" />
                                                <p className="text-lg font-medium text-gray-600">No Images Found</p>
                                                <p className="text-sm text-gray-500">Try create new image!</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-6">
                        {currentImages.length > 0 && (
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(filteredImages.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'flex justify-center space-x-2'}
                                pageClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                previousClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                nextClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                breakClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                activeClassName={'bg-primary-600 text-white'}
                            />
                        )}
                    </div>
                </div>
                <ToastContainer />
            </main>
        </div>
    );
};

export default GeneratedImages;
