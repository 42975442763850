import React, { useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';
import axiosInstance from '../../../Common/axiosInstance';
import Loading from '../../Components/Loading';
import languages from '../../../data/languages.json';

const SocialMediaPostGenerator = () => {
  const [selectedLanguage, setSelectedLanguage] = useState({ value: 'en', label: 'English (US)' });
  const [creativity, setCreativity] = useState({ value: 'regular', label: 'Regular' });
  const [toneOfVoice, setToneOfVoice] = useState({ value: 'professional', label: 'Professional' });
  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [postContent, setPostContent] = useState('');
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const characterLimits = {
    companyName: 100,
    companyDescription: 200,
    postContent: 200,
  };

  const validateInputs = () => {
    const newErrors = {};
    if (companyName.length > characterLimits.companyName) {
      newErrors.companyName = `Company name must be less than ${characterLimits.companyName} characters.`;
    }
    if (companyDescription.length > characterLimits.companyDescription) {
      newErrors.companyDescription = `Company description must be less than ${characterLimits.companyDescription} characters.`;
    }
    if (postContent.length > characterLimits.postContent) {
      newErrors.postContent = `Post content must be less than ${characterLimits.postContent} characters.`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleGenerate = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;

    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 100);

      const res = await axiosInstance.post('/connect/generate-social-media-posts/', {
        language: selectedLanguage ? selectedLanguage.value : '',
        creativity: creativity.value,
        tone_of_voice: toneOfVoice.value,
        company_name: companyName,
        company_description: companyDescription,
        post_content: postContent
      });
      
      if (res) {
        localStorage.removeItem('smpots');
        setProgress(0);
        navigate('/generated-social-media-post', { state: { id: res.data.id } });
      }
    } catch (error) {
      console.error('Error generating social media post:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {isLoading && <Loading progress={progress} message="Generating social media post, please wait..." />}
      <PageHeader title="Social Media Post (Business)" subtitle="Write a post for your business to be published on any social media platform." />
      <div className="w-full max-w-2xl pb-10">
        <div className="bg-white mx-4 md:mx-0 rounded-lg shadow-lg p-8">
          <form onSubmit={handleGenerate}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Language</label>
                <Select
                  options={languages}
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                  placeholder="Select a language"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Creativity</label>
                <Select
                  options={[
                    { value: 'regular', label: 'Regular' },
                    { value: 'high', label: 'High' },
                  ]}
                  value={creativity}
                  onChange={setCreativity}
                  placeholder="Select creativity level"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Tone of voice</label>
                <Select
                  options={[
                    { value: 'professional', label: 'Professional' },
                    { value: 'childish', label: 'Childish' },
                    { value: 'luxurious', label: 'Luxurious' },
                    { value: 'friendly', label: 'Friendly' },
                    { value: 'formal', label: 'Formal' },
                    { value: 'humorous', label: 'Humorous' },
                    { value: 'confident', label: 'Confident' },
                    { value: 'exciting', label: 'Exciting' },
                    { value: 'surprised', label: 'Surprised' },
                    { value: 'academic', label: 'Academic' },
                    { value: 'optimistic', label: 'Optimistic' },
                    { value: 'creative', label: 'Creative' }
                  ]}
                  value={toneOfVoice}
                  onChange={setToneOfVoice}
                  placeholder="Select tone of voice"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Company Name</label>
                <input
                  type="text"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter company name"
                  className={`w-full px-4 py-2 text-gray-600 border ${errors.companyName ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                  maxLength={characterLimits.companyName}
                  required
                />
                {errors.companyName && <p className="text-red-500 text-xs mt-2">{errors.companyName}</p>}
              </div>
            </div>
            <div>
              <label className="block text-gray-700 mb-2">Company Description</label>
              <textarea
                value={companyDescription}
                onChange={(e) => setCompanyDescription(e.target.value)}
                placeholder="Briefly describe what your company is about."
                className={`w-full px-4 py-2 text-gray-600 border ${errors.companyDescription ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                rows="2"
                maxLength={characterLimits.companyDescription}
                required
              />
              {errors.companyDescription && <p className="text-red-500 text-xs mt-2">{errors.companyDescription}</p>}
              <div className="text-right text-gray-500 text-sm mt-1">
                {companyDescription.length}/{characterLimits.companyDescription}
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">What is this post about?</label>
              <textarea
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                placeholder="e.g. Release of our app"
                className={`w-full px-4 py-2 text-gray-600 border ${errors.postContent ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                rows="2"
                maxLength={characterLimits.postContent}
                required
              />
              {errors.postContent && <p className="text-red-500 text-xs mt-2">{errors.postContent}</p>}
              <div className="text-right text-gray-500 text-sm mt-1">
                {postContent.length}/{characterLimits.postContent}
              </div>
            </div>
            <button type="submit" className="w-full py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800 transition duration-300">Generate</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaPostGenerator;
