// components/Modal.js
import React from 'react';
import PropTypes from 'prop-types';

const ModalImage = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75 transition-opacity duration-300">
      <div className="relative bg-white p-4 md:p-8 rounded-lg shadow-lg w-80 md:w-11/12 max-w-xl transform transition-transform duration-300"> 
        {children}
      </div>
    </div>
  );
};

ModalImage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalImage;
