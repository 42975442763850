import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import CommonEditor from '../../Components/Editor';
import Modal from '../../Components/Modal';
import PageHeader from '../../Components/PageHeader';
import beautify from 'js-beautify';
import { getBlog, updateBlog } from '../../../redux/actions/connectActions';

const Blog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state?.id;
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [editorHtml, setEditorHtml] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [errors, setErrors] = useState('');

    const getBlogDetails = useSelector(state => state.blog.blog);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getBlog(id));
            } catch (error) {
                console.error('Failed to fetch blog', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    useEffect(() => {
        if (getBlogDetails) {
            setEditorHtml(getBlogDetails.content);
            setHtmlContent(beautify.html(getBlogDetails.content)); // Beautify the HTML content
        }
    }, [getBlogDetails]);

    const handleEditorChange = (content) => {
        setEditorHtml(content);
        setHtmlContent(beautify.html(content)); // Beautify the HTML content
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveHtml = () => {
        if (htmlContent.length > 10000) {  // Set your desired character limit here
            setErrors('HTML content must be less than 10,000 characters.');
            return;
        }
        setEditorHtml(htmlContent);
        setIsModalOpen(false);
        setErrors('');
    };

    const handleUpdateBlog = async () => {
        if (editorHtml.length > 10000) {  // Validate character length before updating
            toast.error('HTML content must be less than 10,000 characters.');
            return;
        }

        try {
            setUpdating(true); // Set updating state to true
            const formData = {
                content: editorHtml,
            };
            await dispatch(updateBlog(id, formData));
            toast.success('Blog updated successfully');
        } catch (error) {
            console.error('Failed to update blog', error);
            toast.error('Failed to update blog');
        } finally {
            setUpdating(false); // Set updating state to false
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!getBlogDetails) {
        return <div>No blog found</div>;
    }

    return (
        <main className="p-4 min-h-screen">
            <PageHeader title="Blog Post Generator" subtitle="Generate paragraphs for your outline or write your own content" />
            <ToastContainer />

            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="prose mb-6">
                    <CommonEditor
                        value={editorHtml}
                        onChange={handleEditorChange}
                    />
                </div>
                <div className="flex justify-center space-x-4 mt-6">
                    <button
                        onClick={handleOpenModal}
                        className="px-4 py-2 text-primary-600 bg-white font-semibold border border-primary-600 rounded-full bg-gradient-to-r hover:from-primary-600 hover:to-primary-800 hover:text-white"
                    >
                        Edit HTML
                    </button>
                    <button
                        onClick={handleUpdateBlog}
                        className={`px-4 py-2 rounded-full font-semibold border border-primary-600 text-white ${updating ? 'bg-gradient-to-r from-gray-500 to-gray-700' : 'bg-gradient-to-r from-primary-500 to-primary-700 hover:from-primary-600 hover:to-primary-800'}`}
                        disabled={updating} // Disable button while updating
                    >
                        {updating ? 'Updating...' : 'Update Blog'}
                    </button>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <h2 className="text-xl font-bold mb-4">Source Code</h2>
                <textarea
                    className="w-full h-64 p-2 border rounded font-mono"
                    value={htmlContent}
                    onChange={(e) => setHtmlContent(e.target.value)}
                />
                {errors && <p className="text-red-500 text-xs mt-2">{errors}</p>}
                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleCloseModal}
                        className="px-4 py-2 bg-gradient-to-r from-gray-500 to-gray-700 text-white rounded-lg hover:from-gray-600 hover:to-gray-800 mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSaveHtml}
                        className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800"
                    >
                        Save
                    </button>
                </div>
            </Modal>
        </main>
    );
};

export default Blog;
