import React, { useEffect, useState } from 'react';
import { FaUserEdit, FaEnvelope, FaPhone, FaMapMarkerAlt, FaSave, FaTimes, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateProfile, userProfile } from '../../redux/actions/authActions';

const Profile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [profile, setProfile] = useState({
        name: 'No Name',
        email: user?.email || 'No Email',
        role: 'Admin',
        phone: 'No Phone',
        address: 'No Address'
    });
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    const characterLimits = {
        name: 50,
        phone: 15,
        address: 200
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(userProfile());
                if (data) {
                    setProfile({
                        name: data.name || "No Name",
                        email: data.email || "No Email",
                        role: 'Admin',
                        phone: data.phone || "No Phone",
                        address: data.address || "No Address"
                    });
                }
            } catch (error) {
                console.log(error.message);
            }
        };

        fetchData();
    }, [dispatch]);

    const validateInputs = () => {
        const newErrors = {};
        if (profile.name.length > characterLimits.name) {
            newErrors.name = `Name must be less than ${characterLimits.name} characters.`;
        }
        if (profile.phone.length > characterLimits.phone) {
            newErrors.phone = `Phone number must be less than ${characterLimits.phone} characters.`;
        }
        if (profile.address.length > characterLimits.address) {
            newErrors.address = `Address must be less than ${characterLimits.address} characters.`;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateInputs()) return;

        try {
            dispatch(updateProfile(profile)).then(() => {
                toast.success('Profile updated successfully');
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Profile update error:', error);
        }
    };

    return (
        <div className='p-4 min-h-screen'>
            <div className="grid grid-col-1 mt-6 mb-6">
                <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Admin Profile</h1>
                <p className="text-gray-500 dark:text-gray-300"></p>
            </div>
            <main className="p-0 md:p-6 min-h-screen">
                <div className="max-w-5xl mx-auto p-4 md:p-6 md:pt-12 pb-20 bg-white dark:bg-gray-900 rounded-lg shadow-md">
                    {isEditing ? (
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="mt-4 md:col-span-1">
                                    <label className="block text-gray-700 dark:text-gray-300 mb-1" htmlFor="name">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={profile.name}
                                        onChange={handleChange}
                                        className={`w-full px-4 py-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400`}
                                        required
                                        maxLength={characterLimits.name}
                                    />
                                    {errors.name && <p className="text-red-500 text-xs mt-2">{errors.name}</p>}
                                </div>
                                <div className="mt-4 md:col-span-1">
                                    <label className="block text-gray-700 dark:text-gray-300 mb-1" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={profile.email}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                        disabled
                                    />
                                </div>
                                <div className="mt-4 md:col-span-1">
                                    <label className="block text-gray-700 dark:text-gray-300 mb-1" htmlFor="role">
                                        Role
                                    </label>
                                    <input
                                        type="text"
                                        id="role"
                                        name="role"
                                        value={profile.role}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border border-gray-300 rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                        disabled
                                    />
                                </div>
                                <div className="mt-4 md:col-span-1">
                                    <label className="block text-gray-700 dark:text-gray-300 mb-1" htmlFor="phone">
                                        Phone
                                    </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={profile.phone}
                                        onChange={handleChange}
                                        className={`w-full px-4 py-2 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400`}
                                        maxLength={characterLimits.phone}
                                    />
                                    {errors.phone && <p className="text-red-500 text-xs mt-2">{errors.phone}</p>}
                                </div>
                                <div className="mt-4 md:col-span-2">
                                    <label className="block text-gray-700 dark:text-gray-300 mb-1" htmlFor="address">
                                        Address
                                    </label>
                                    <textarea
                                        id="address"
                                        name="address"
                                        value={profile.address}
                                        onChange={handleChange}
                                        className={`w-full px-4 py-2 border ${errors.address ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-400`}
                                        rows="3"
                                        required
                                        maxLength={characterLimits.address}
                                    ></textarea>
                                    {errors.address && <p className="text-red-500 text-xs mt-2">{errors.address}</p>}
                                    <div className="text-right text-gray-500 text-sm mt-1">
                                        {profile.address.length}/{characterLimits.address}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center space-x-4">
                                <button
                                    type="submit"
                                    className="flex items-center px-6 py-2 text-white bg-primary-600 rounded-md shadow-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                >
                                    <FaSave className="mr-2" />
                                    Save Changes
                                </button>
                                <button
                                    type="button"
                                    className="flex items-center border border-primary-700 px-6 py-2 text-primary-700 bg-white rounded-md shadow-md hover:bg-primary-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary-400"
                                    onClick={() => setIsEditing(false)}
                                >
                                    <FaTimes className="mr-2" />
                                    Cancel
                                </button>
                            </div>
                        </form>
                    ) : (
                        <div className="space-y-6">
                            <div className="flex justify-center">
                                <FaUser className="text-primary-400 p-2 text-6xl mb-4 border-4 border-primary-400 rounded-full" />
                            </div>
                            <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                                <div className="flex items-center space-x-4 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md">
                                    <FaUserEdit className="text-xl text-primary-600" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">Name</h3>
                                        <p className="text-gray-900 dark:text-gray-200">{profile &&  profile.name}</p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md">
                                    <FaEnvelope className="text-xl text-primary-600" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">Email</h3>
                                        <p className="text-gray-900 dark:text-gray-200">{profile && profile.email}</p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md">
                                    <FaUserEdit className="text-xl text-primary-600" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">Role</h3>
                                        <p className="text-gray-900 dark:text-gray-200">{profile && profile.role}</p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md">
                                    <FaPhone className="text-xl text-primary-600" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">Phone</h3>
                                        <p className="text-gray-900 dark:text-gray-200">{profile && profile.phone}</p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4 bg-white dark:bg-gray-800 rounded-lg p-4 shadow-md">
                                    <FaMapMarkerAlt className="text-xl text-primary-600" />
                                    <div>
                                        <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300">Address</h3>
                                        <p className="text-gray-900 dark:text-gray-200">{profile && profile.address}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <button
                                    type="button"
                                    className="flex items-center font-semibold mt-8 px-6 py-2 text-white bg-primary-600 rounded-md shadow-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
                                    onClick={() => setIsEditing(true)}
                                >
                                    <FaUserEdit className="mr-2" />
                                    Edit Profile
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <ToastContainer />
        </div>
    );
};

export default Profile;
