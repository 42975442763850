import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import CommonEditor from '../../Components/Editor';
import PageHeader from '../../Components/PageHeader';
import Modal from '../../Components/Modal';
import beautify from 'js-beautify';

const StepContent = ({ prevStep, handleChange, values, currentStep, steps }) => {
  const [content, setContent] = useState(values.content);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState(beautify.html(values.content));
  const [updating, setUpdating] = useState(false);

  const handleEditorChange = (content) => {
    setContent(content);
    handleChange('content', content);
    setHtmlContent(beautify.html(content)); // Beautify the HTML content
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveHtml = () => {
    setContent(htmlContent);
    handleChange('content', htmlContent);
    setIsModalOpen(false);
  };

  const handleUpdateArticle = () => {
    setUpdating(true);
    setTimeout(() => {
      toast.success('Blog post created successfully');
      setUpdating(false);
    }, 2000); // Simulating an API call
  };

  return (
    <div className="w-full md:max-w-5xl pb-10 md:pb-40 px-2 md:px-0">
      <PageHeader title="Blog Post Generator" subtitle="Generate paragraphs for your outline or write your own content" />
      <div className="bg-white rounded-b-lg shadow-lg p-8">
        <div className="mt-4">
          <CommonEditor
            value={content}
            onChange={handleEditorChange}
          />
        </div>
        <div className="flex justify-center space-x-4 mt-6">
          <button
            onClick={handleOpenModal}
            className="px-4 py-2 text-primary-600 bg-white font-semibold border border-primary-600 rounded-full bg-gradient-to-r hover:from-primary-600 hover:to-primary-800 hover:text-white"
          >
            Edit HTML
          </button>
          <button
            onClick={handleUpdateArticle}
            className={`px-4 py-2 rounded-full font-semibold border border-primary-600 text-white ${updating ? 'bg-gradient-to-r from-gray-500 to-gray-700' : 'bg-gradient-to-r from-primary-500 to-primary-700 hover:from-primary-600 hover:to-primary-800'}`}
            disabled={updating}
          >
            {updating ? 'Updating...' : 'Submit'}
          </button>
        </div> 
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <h2 className="text-xl font-bold mb-4">Source Code</h2>
        <textarea
          className="w-full h-64 p-2 border rounded font-mono"
          value={htmlContent}
          onChange={(e) => setHtmlContent(e.target.value)}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={handleCloseModal}
            className="px-4 py-2 bg-gradient-to-r from-gray-500 to-gray-700 text-white rounded-lg hover:from-gray-600 hover:to-gray-800 mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveHtml}
            className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800"
          >
            Save
          </button>
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default StepContent;
