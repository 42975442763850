import React, { useState } from 'react';

const faqs = [
  {
    question: "What is Connect AI Box?",
    answer: "Connect AI Box is an advanced platform that leverages artificial intelligence to enhance content creation and business automation, providing AI-driven solutions for various industries."
  },
  {
    question: "How does the AI-powered content generation work?",
    answer: "Our AI algorithms analyze the input data and generate high-quality, SEO-optimized content tailored to your needs, whether it's for blog posts, articles, or social media."
  },
  {
    question: "Is Connect AI Box secure?",
    answer: "Yes, Connect AI Box uses state-of-the-art security measures to ensure that your data is protected. We comply with all major data protection regulations."
  },
  {
    question: "Can I integrate Connect AI Box with my existing software?",
    answer: "Absolutely! Connect AI Box offers robust API support, making it easy to integrate with most existing software solutions, including CMS and marketing platforms."
  },
  {
    question: "What support options are available?",
    answer: "We offer a range of support options including email support, live chat, and a comprehensive knowledge base to help you with any issues you might encounter."
  }
];

const ContactFAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-gray-50 pt-12 pb-24">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Frequently Asked Questions
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <button
                onClick={() => toggleFAQ(index)}
                className="flex justify-between items-center w-full text-left text-gray-900 font-medium focus:outline-none"
              >
                <span>{faq.question}</span>
                <svg
                  className={`w-6 h-6 transform transition-transform duration-200 ${activeIndex === index ? 'rotate-180' : ''
                    }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {activeIndex === index && (
                <div className="mt-4 text-gray-700">
                  {faq.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContactFAQ;
