import React, { useState } from 'react';
import Select from 'react-select';
import PageHeader from '../../Components/PageHeader';
import StepNavigation from './StepNavigation';

const StepDetails = ({ nextStep, handleChange, values, currentStep, steps }) => {
  const [descriptionError, setDescriptionError] = useState(false);
  const [keywordError, setKeywordError] = useState(false);
  const [remainingCharsDescription, setRemainingCharsDescription] = useState(300);
  const [remainingCharsKeyword, setRemainingCharsKeyword] = useState(100);

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= 300) {
      handleChange('description', value);
      setRemainingCharsDescription(300 - value.length);
      setDescriptionError(value.length < 40 || value.length > 300);
    }
  };

  const handleKeywordChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      handleChange('keyword', value);
      setRemainingCharsKeyword(100 - value.length);
      setKeywordError(value.length > 100);
    }
  };

  const options = [
    { value: 'en', label: 'English (US)' },
    { value: 'es', label: 'Spanish' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'zh', label: 'Chinese' },
    { value: 'ja', label: 'Japanese' },
    { value: 'hi', label: 'Hindi' },
    { value: 'ar', label: 'Arabic' },
    { value: 'pt', label: 'Portuguese' },
    { value: 'ru', label: 'Russian' },
    { value: 'ko', label: 'Korean' },
    { value: 'it', label: 'Italian' },
    { value: 'tr', label: 'Turkish' },
    { value: 'nl', label: 'Dutch' },
    { value: 'pl', label: 'Polish' },
    { value: 'sv', label: 'Swedish' },
    { value: 'no', label: 'Norwegian' },
    { value: 'da', label: 'Danish' },
    { value: 'fi', label: 'Finnish' },
    { value: 'el', label: 'Greek' },
  ];

  return (
    <div className="w-full md:max-w-xl pb-10 md:pb-40 px-2 md:px-0">
      <PageHeader title="Blog Post Generator" subtitle="Create a blog post in minutes" />
      <StepNavigation currentStep={currentStep} steps={steps} />
      <div className="bg-white rounded-b-lg shadow-lg p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Language</label>
            <Select
              options={options}
              value={options.find(option => option.value === values.language)}
              onChange={(option) => handleChange('language', option.value)}
              styles={{
                control: (base) => ({
                  ...base,
                  borderRadius: '0.475rem',
                  padding: '1px',
                  borderColor: '#cbd5e0',
                  '&:hover': { borderColor: '#a0aec0' },
                  boxShadow: 'none',
                }),
                menu: (base) => ({
                  ...base,
                  borderRadius: '0.375rem',
                  borderColor: '#cbd5e0',
                }),
              }}
            />
          </div>
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Creativity</label>
            <select
              className="w-full p-2 text-gray-600 border border-gray-300 rounded-lg"
              value={values.creativity}
              onChange={(e) => handleChange('creativity', e.target.value)}
            >
              <option value="regular">Regular</option>
              <option value="creative">Creative</option>
            </select>
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-gray-700 font-semibold mb-2">What do you want to write about?*</label>
          <textarea
            className="w-full p-2 text-gray-600 border border-gray-300 rounded-lg"
            value={values.description}
            onChange={handleDescriptionChange}
            placeholder="Explain what your blog post is about (min. 40 characters, max. 300 characters). e.g. A blog article explaining how copywriting can drive more traffic to your website."
            rows={4}
          />
          {descriptionError && (
            <div className="text-red-500 mt-2">
              <small>
                {remainingCharsDescription} characters remaining. Description must be between 40 and 300 characters.
              </small>
            </div>
          )}
        </div>
        <div className="mt-4">
          <label className="block text-gray-700 font-semibold mb-2">Targeted Keyword (optional)</label>
          <input
            className="w-full p-2 text-gray-600 border border-gray-300 rounded-lg"
            type="text"
            value={values.keyword}
            onChange={handleKeywordChange}
            placeholder="e.g. ai copywriting"
          />
          {keywordError && (
            <div className="text-red-500 mt-2">
              <small>Keyword must be less than 100 characters.</small>
            </div>
          )}
        </div>
        <div className="mt-6 text-center">
          <button
            className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg disabled:opacity-50"
            onClick={nextStep}
            disabled={descriptionError || keywordError}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepDetails;
