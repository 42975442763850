import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { resendVerifyEmail } from "../../../redux/actions/authActions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EmailVerification = ({ email }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const emailFromState = location.state?.email || email;

  useEffect(() => {
    if (!emailFromState) {
      navigate("/signin");
    }
  }, [emailFromState, navigate]);

  const handleResendVerification = () => {
    setIsSubmitting(true);
    dispatch(resendVerifyEmail(emailFromState)).then((res) => {
      setIsSubmitting(false);
      if (res) {
        toast.success('Verification link has been sent to your email.');
      } else {
        toast.error('Failed to send verification link. Please try again.');
      }
    }).catch(() => {
      setIsSubmitting(false);
      toast.error('Failed to send verification link. Please try again.');
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary-50 to-gray-50 py-12">
      <div className="max-w-md w-full bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center mb-8">
          <div className="flex items-center justify-center mb-4">
            <div className="w-10 h-[1px] bg-gray-300"></div>
            <EmailIcon className="mx-3 text-primary-500" />
            <div className="w-10 h-[1px] bg-gray-300"></div>
          </div>
          <h2 className="text-3xl font-bold text-gray-900">
            Verify your E-mail Address
          </h2>
          <p className="text-gray-600 mt-2">
            Please check your email for the verification link.
          </p>
        </div>
        <button
          onClick={handleResendVerification}
          className={`group relative w-full flex justify-center py-3 px-4 border border-transparent font-medium rounded-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition duration-300 ${isSubmitting ? 'cursor-not-allowed' : ''}`}
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Resend Verification Link'}
        </button>
        <div className="mt-6 text-center text-gray-700">
          <p>
            We have sent a verification link to your email address. Please click on the link in the email to verify your account. If you did not receive the email, check your spam folder or click the button above to resend the link.
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const EmailIcon = ({ className }) => {
  return (
    <svg
      className={className}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path>
    </svg>
  );
};

export default EmailVerification;
