import React, { useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';
import axiosInstance from '../../../Common/axiosInstance';
import Loading from '../../Components/Loading';
import languages from '../../../data/languages.json';  

const RewriteContentGenerator = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [contentToRewrite, setContentToRewrite] = useState('');
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [charError, setCharError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const MAX_CHAR_LIMIT = 2000; // Maximum number of characters allowed

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (contentToRewrite.length > MAX_CHAR_LIMIT) {
      setCharError(`Content exceeds the maximum limit of ${MAX_CHAR_LIMIT} characters.`);
      return;
    }
    setIsLoading(true); 
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 2;
        });
      }, 100);
      const res = await axiosInstance.post('/connect/generate-rewrites/', {
        language: selectedLanguage ? selectedLanguage.value : '',
        content: contentToRewrite
      }); 
      if (res) {
        setProgress(0);
        localStorage.removeItem('rewrittenContents');
        navigate('/generated-rewrite-content', { state: { id: res.data.id } });
      }
    } catch (error) {
      console.error('Error rewriting content:', error);
    }
    setIsLoading(false);
  };

  const handleContentChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_CHAR_LIMIT) {
      setContentToRewrite(value);
      setCharError(''); // Clear error message when content is within limit
    } else {
      setCharError(`Content exceeds the maximum limit of ${MAX_CHAR_LIMIT} characters.`);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {isLoading && <Loading progress={progress} message="Rewrite Content, please wait..." />}
      <PageHeader title="Rewrite content with AI" subtitle="The smartest tool to rewrite articles or any content." />
      <div className="w-full max-w-3xl pb-10">
        <div className="bg-white mx-4 md:mx-0  rounded-lg shadow-lg p-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Language</label>
              <Select
                options={languages}
                value={selectedLanguage}
                onChange={setSelectedLanguage}
                placeholder="Select a language"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: '0.475rem',
                    padding: '3px',
                    borderColor: '#cbd5e0',
                    '&:hover': { borderColor: '#a0aec0' },
                    boxShadow: 'none'
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: '0.375rem',
                    borderColor: '#cbd5e0',
                  })
                }}
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">What would you like to rewrite?</label>
              <textarea
                value={contentToRewrite}
                onChange={handleContentChange}
                placeholder="Paste the content article that you want to rewrite"
                className={`w-full px-4 py-2 text-gray-600 border ${charError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                rows="4"
                required
              />
              {charError && (
                <p className="text-red-500 mt-2 text-sm">{charError}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800 transition duration-300"
              disabled={isLoading || charError}
            >
              Start rewriting
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RewriteContentGenerator;
