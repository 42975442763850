import React from 'react';

const testimonials = [
  {
    name: "Alex Johnson",
    role: "Mobile Developer at TechCorp",
    image: "https://randomuser.me/api/portraits/men/45.jpg",
    text: "Connect AI Box has drastically improved our content creation process. The AI-powered tools are a game-changer for our team, saving us countless hours each week."
  },
  {
    name: "Emily Davis",
    role: "Marketing Specialist at Creative Solutions",
    image: "https://randomuser.me/api/portraits/women/65.jpg",
    text: "The seamless integration with our existing systems made the transition to Connect AI Box effortless. The support team was always available to help."
  },
  {
    name: "Michael Brown",
    role: "Software Developer at CodeWorks",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    text: "The accuracy of the AI-driven content generation has been remarkable. Connect AI Box has reduced errors and improved our overall workflow efficiency."
  },
  {
    name: "Sarah Wilson",
    role: "Project Manager at InnovateX",
    image: "https://randomuser.me/api/portraits/women/68.jpg",
    text: "Connect AI Box's data visualization tools have provided us with clear insights and helped us make informed decisions quickly and effectively."
  },
  {
    name: "David Lee",
    role: "Operations Manager at LogisticsPro",
    image: "https://randomuser.me/api/portraits/men/78.jpg",
    text: "The reliability and uptime of Connect AI Box's services have been exceptional. We can trust that our operations will run smoothly at all times."
  },
  {
    name: "Laura White",
    role: "Financial Analyst at MoneyMatters",
    image: "https://randomuser.me/api/portraits/women/72.jpg",
    text: "Using Connect AI Box has been a great decision for our financial team. The automated content generation has streamlined our processes and improved accuracy."
  },
];

const columnSpans = [
  'col-span-2',
  'col-span-3',
  'col-span-3',
  'col-span-2',
  'col-span-2',
  'col-span-3'
];

const TestimonialsSection = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-center flex-col gap-y-2 py-12 md:pt-4 md:pb-12">
          <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-2">
            Discover how Connect AI Box can benefit you
          </h2>
          <p className="text-lg text-gray-600 text-center">
            See what our satisfied customers have to say about our AI-driven solutions.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-5 w-full">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className={`border p-7 rounded-xl bg-white shadow-lg ${columnSpans[index]} flex flex-col gap-y-10 justify-between`}
            >
              <div className="flex flex-col gap-y-3.5">
                <p className="font-semibold text-gray-900 text-lg">{testimonial.name.split(' ')[0]} says:</p>
                <p className="text-gray-700">
                  {testimonial.text}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="h-12 w-12 rounded-full"
                />
                <div>
                  <p className="text-base font-semibold">{testimonial.name}</p>
                  <p className="text-sm text-gray-600">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
