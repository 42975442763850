import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, googleAuth } from '../../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; // Ensure correct import
import { useAuth } from '../../../Common/AuthProvider';

const SignupPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { loading, error } = authState;
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);
        }
    }, [isAuthenticated]);

    const characterLimits = {
        firstName: 50,
        lastName: 50,
        email: 100,
        password: 20,
    };

    const validate = () => {
        const errors = {};
        if (!firstName) {
            errors.firstName = 'First name is required.';
        } else if (firstName.length > characterLimits.firstName) {
            errors.firstName = `First name must be less than ${characterLimits.firstName} characters.`;
        }
        if (!lastName) {
            errors.lastName = 'Last name is required.';
        } else if (lastName.length > characterLimits.lastName) {
            errors.lastName = `Last name must be less than ${characterLimits.lastName} characters.`;
        }
        if (!email) {
            errors.email = 'Email is required.';
        } else if (email.length > characterLimits.email) {
            errors.email = `Email must be less than ${characterLimits.email} characters.`;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
        } else if (password.length > characterLimits.password) {
            errors.password = `Password must be less than ${characterLimits.password} characters.`;
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const userData = {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
            };
            try {
                const response = await dispatch(register(userData));
                if (response) {
                    navigate('/email-verification', { state: { email: email } });
                }
            } catch (error) {
                console.error("Registration error:", error);
            }
        }
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const userData = {
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            id_token: token,
        };
        await dispatch(googleAuth(userData)).then((res) => {
            if (res && res.access) {
                login(res.access);
                localStorage.setItem('user', JSON.stringify(res.user));
            }
        });
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Sign Up Failed:', error);
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary-50 to-gray-50 py-12">
            <div className="max-w-md mx-4 w-full bg-white p-8 rounded-xl shadow-lg">
                <div className="text-center mb-8">
                    <h2 className="text-2xl md:text-3xl font-bold text-gray-900">
                        Sign Up for ConnectAI
                    </h2>
                    <p className="text-gray-600">
                        Or <Link to="/signin" className="font-medium text-primary-500 hover:text-primary-600">sign in to your account</Link>
                    </p>
                </div>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                            First Name
                        </label>
                        <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.firstName ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                            maxLength={characterLimits.firstName}
                        />
                        {errors.firstName && <p className="text-red-500 text-xs mt-2">{errors.firstName}</p>}
                    </div>

                    <div>
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                            Last Name
                        </label>
                        <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.lastName ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                            maxLength={characterLimits.lastName}
                        />
                        {errors.lastName && <p className="text-red-500 text-xs mt-2">{errors.lastName}</p>}
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.email ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                            maxLength={characterLimits.email}
                        />
                        {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={`appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white`}
                            maxLength={characterLimits.password}
                        />
                        {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-3 px-4 border border-transparent font-medium rounded-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            <svg
                                className="w-6 h-6 mr-2"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                <circle cx="8.5" cy="7" r="4" />
                                <path d="M20 8v6M23 11h-6" />
                            </svg>
                            Sign Up
                        </button>
                    </div>
                </form>
                <div className="text-center mt-6">
                    <p className="font-bold text-gray-500">OR</p>
                </div>
                <div className="w-full flex-1 mt-8">
                    <div className="flex flex-col items-center">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                        />
                    </div>
                </div>
                {loading && <p className="mt-4 text-gray-600">Loading...</p>}
                {error && (
                    <div className="mt-3 text-red-500">
                        <p>{error}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SignupPage;
