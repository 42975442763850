import React, { useState } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import { FaGlobeAmericas, FaSearch } from 'react-icons/fa';
import { generateArticle } from '../../../redux/actions/connectActions';
import PageHeader from '../../Components/PageHeader';
import Loading from '../../Components/Loading';

const customSingleValue = ({ data }) => (
  <div className="flex items-center">
    <FaGlobeAmericas className="mr-2" />
    {data.label}
  </div>
);

const customDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FaSearch />
  </components.DropdownIndicator>
);

const languages = [
  { value: 'en', label: 'English (US)' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'zh', label: 'Chinese' },
  { value: 'ja', label: 'Japanese' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ar', label: 'Arabic' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'ru', label: 'Russian' },
  { value: 'ko', label: 'Korean' },
  { value: 'it', label: 'Italian' },
  { value: 'tr', label: 'Turkish' },
  { value: 'nl', label: 'Dutch' },
  { value: 'pl', label: 'Polish' },
  { value: 'sv', label: 'Swedish' },
  { value: 'no', label: 'Norwegian' },
  { value: 'da', label: 'Danish' },
  { value: 'fi', label: 'Finnish' },
  { value: 'el', label: 'Greek' }
];

const ArticleGenerator = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [articleTitle, setArticleTitle] = useState('');
  const [minWordCount, setMinWordCount] = useState('0');
  const [maxWordCount, setMaxWordCount] = useState('1200');
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNumberInput = (e, setter) => {
    const value = e.target.value;
    if (value === '' || (/^[0-9\b]+$/.test(value) && value <= 1500)) {
      setter(value);
      if (e.target.name === 'maxWordCount' && value > 1500) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          maxWordCount: 'Maximum word count must be less than or equal to 1500.',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          maxWordCount: '',
        }));
      }
    }
  };

  const validate = () => {
    const errors = {};
    if (!keyword) {
      errors.keyword = 'Keyword is required.';
    } else if (keyword.length > 200) {
      errors.keyword = 'Keyword must be less than 200 characters.';
    }

    if (!articleTitle) {
      errors.articleTitle = 'Article title is required.';
    } else if (articleTitle.length > 200) {
      errors.articleTitle = 'Article title must be less than 200 characters.';
    }

    if (maxWordCount > 1500) {
      errors.maxWordCount = 'Maximum word count must be less than or equal to 1500.';
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isLoading) return; // Prevent multiple submissions

    const validationErrors = validate();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const data = {
      language: selectedLanguage ? selectedLanguage.value : '',
      keyword,
      articleTitle,
      minWordCount,
      maxWordCount,
    };

    const fakeProgress = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(fakeProgress);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 200);

    try {
      const res = await dispatch(generateArticle(data));
      if (res.id) {
        localStorage.removeItem('articles');
        navigate('/generated-article', { state: { id: res.id } });
      }
    } catch (error) {
      console.error('Generate article error:', error);
    } finally {
      clearInterval(fakeProgress);
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <PageHeader title="Article Generator" subtitle="Turn a title and outline into a long and engaging article." />
      <div className="w-full max-w-md pb-10">
        {isLoading ? (
          <Loading progress={progress} message="Generating article..." />
        ) : (
          <div className="bg-white mx-4 md:mx-0 rounded-lg shadow-lg p-8">
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-gray-700 mb-2">Language</label>
                <Select
                  options={languages}
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                  placeholder="Select a language"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none',
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    }),
                  }}
                  components={{ SingleValue: customSingleValue, DropdownIndicator: customDropdownIndicator }}
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 mb-2">Keyword*</label>
                <input
                  type="text"
                  value={keyword}
                  placeholder="Keyword"
                  onChange={(e) => setKeyword(e.target.value)}
                  className={`w-full px-4 py-2 text-gray-600 border ${errors.keyword ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                  required
                />
                {errors.keyword && <p className="text-red-500 text-xs mt-2">{errors.keyword}</p>}
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 mb-2">Article Title*</label>
                <div className="flex items-center">
                  <input
                    type="text"
                    value={articleTitle}
                    placeholder='e.g. "The Ultimate Guide to Coffee in New York"'
                    onChange={(e) => setArticleTitle(e.target.value)}
                    className={`w-full px-4 py-2 text-gray-600 border ${errors.articleTitle ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                    required
                  />
                </div>
                {errors.articleTitle && <p className="text-red-500 text-xs mt-2">{errors.articleTitle}</p>}
              </div>
              <div className="flex space-x-4 mb-6">
                <div className="w-1/2">
                  <label className="block text-gray-700 mb-2">Minimum Word Count</label>
                  <input
                    type="text"
                    value={minWordCount}
                    onChange={(e) => handleNumberInput(e, setMinWordCount)}
                    className="w-full px-4 py-2 text-gray-600 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400"
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-700 mb-2">Maximum Word Count</label>
                  <input
                    type="text"
                    name="maxWordCount"
                    value={maxWordCount}
                    onChange={(e) => handleNumberInput(e, setMaxWordCount)}
                    className={`w-full px-4 py-2 text-gray-600 border ${errors.maxWordCount ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                  />
                  {errors.maxWordCount && (
                    <p className="text-red-500 text-xs mt-2">{errors.maxWordCount}</p>
                  )}
                </div>
              </div>
              <button type="submit" className="w-full py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800 transition duration-300">
                Submit
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleGenerator;
