import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { contactMessage } from '../../../redux/actions/authActions';
import { motion } from 'framer-motion';

const ContactForm = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      await dispatch(contactMessage(data));
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error('Failed to submit your message. Please try again.');
    }
  };

  const handleCloseModal = () => {
    setIsSubmitted(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-lg px-8 py-10 mx-auto bg-white rounded-lg shadow-lg dark:bg-gray-900"
    >
      <h3 className="inline-block px-3 py-1 mb-4 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">
        Get in touch with us  
      </h3>
      <h1 className="text-3xl font-semibold text-gray-800 capitalize dark:text-white">
        How we can help you?
      </h1>
      <form className="mt-6 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Enter your name*</label>
            <input
              type="text"
              {...register('name', {
                required: 'Name is required',
                maxLength: { value: 50, message: 'Name cannot exceed 50 characters' }
              })}
              placeholder="What's your good name?"
              className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            {errors.name && <span className="text-red-500 text-sm">{errors.name.message}</span>}
          </div>
          <div>
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address*</label>
            <input
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
              })}
              placeholder="Enter your email address"
              className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            {errors.email && <span className="text-red-500 text-sm">{errors.email.message}</span>}
          </div>
          <div>
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Phone number</label>
            <input
              type="text"
              {...register('phone', {
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Invalid phone number',
                },
                maxLength: { value: 15, message: 'Phone number cannot exceed 15 digits' }
              })}
              placeholder="Enter your phone number"
              className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            {errors.phone && <span className="text-red-500 text-sm">{errors.phone.message}</span>}
          </div>
          <div>
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Subject</label>
            <input
              type="text"
              {...register('subject', {
                maxLength: { value: 100, message: 'Subject cannot exceed 100 characters' }
              })}
              placeholder="How can we help you?"
              className="block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            {errors.subject && <span className="text-red-500 text-sm">{errors.subject.message}</span>}
          </div>
        </div>
        <div className="mt-6">
          <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Your message</label>
          <textarea
            {...register('message', {
              maxLength: { value: 500, message: 'Message cannot exceed 500 characters' }
            })}
            className="block w-full h-24 px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md md:h-24 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40"
            placeholder="Describe about your project"
          ></textarea>
          {errors.message && <span className="text-red-500 text-sm">{errors.message.message}</span>}
        </div>
        <button className="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform rounded-full bg-gradient-to-r from-primary-600 to-primary-500 text-white hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600">
          Get in touch
        </button>
      </form>
      <Modal isOpen={isSubmitted} onClose={handleCloseModal}>
        <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
        <p className="text-gray-700 mb-4">Your message has been successfully sent. We'll get back to you soon!</p>
        <button
          onClick={handleCloseModal}
          className="px-4 py-2 bg-primary-500 text-white rounded-full hover:bg-primary-600"
        >
          Close
        </button>
      </Modal>
    </motion.div>
  );
};

export default ContactForm;
