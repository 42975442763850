import {
    GET_ARTICLE_LOADING,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_FAIL,

    GET_BLOG_LOADING,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAIL,

    GET_REWRITTEN_CONTENT_LOADING,
    GET_REWRITTEN_CONTENT_SUCCESS,
    GET_REWRITTEN_CONTENT_FAIL,

    GET_PRODUCT_DESCRIPTION_LOADING,
    GET_PRODUCT_DESCRIPTION_SUCCESS,
    GET_PRODUCT_DESCRIPTION_FAIL,

    GET_SOCIAL_MEDIA_POST_LOADING,
    GET_SOCIAL_MEDIA_POST_SUCCESS,
    GET_SOCIAL_MEDIA_POST_FAIL,

    GET_GENERATED_IMAGE_LOADING,
    GET_GENERATED_IMAGE_SUCCESS,
    GET_GENERATED_IMAGE_FAIL,
} from '../constants/connectConstants';

export const getArticleReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ARTICLE_LOADING:
            return { loading: true };

        case GET_ARTICLE_SUCCESS:
            return { loading: false, article: action.payload };

        case GET_ARTICLE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}

export const getBlogReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_BLOG_LOADING:
            return { loading: true };

        case GET_BLOG_SUCCESS:
            return { loading: false, blog: action.payload };

        case GET_BLOG_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}

export const getRewrittenContentReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_REWRITTEN_CONTENT_LOADING:
            return { loading: true };

        case GET_REWRITTEN_CONTENT_SUCCESS:
            return { loading: false, rewrittenContent: action.payload };

        case GET_REWRITTEN_CONTENT_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}

export const getProductDescriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PRODUCT_DESCRIPTION_LOADING:
            return { loading: true };

        case GET_PRODUCT_DESCRIPTION_SUCCESS:
            return { loading: false, productDescription: action.payload };

        case GET_PRODUCT_DESCRIPTION_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}

export const getSocialMediaPostReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_SOCIAL_MEDIA_POST_LOADING:
            return { loading: true };

        case GET_SOCIAL_MEDIA_POST_SUCCESS:
            return { loading: false, socialMediaPost: action.payload };

        case GET_SOCIAL_MEDIA_POST_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}

export const getGeneratedImageReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_GENERATED_IMAGE_LOADING:
            return { loading: true };

        case GET_GENERATED_IMAGE_SUCCESS:
            return { loading: false, generatedImage: action.payload };

        case GET_GENERATED_IMAGE_FAIL:
            return { loading: false, error: action.payload };

        default:
            return state;
    }
}