import React, { useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';
import axiosInstance from '../../../Common/axiosInstance';
import Loading from '../../Components/Loading';
import languages from '../../../data/languages.json';

const ProductDescriptionGenerator = () => {
  const [selectedLanguage, setSelectedLanguage] = useState({ "value": "en", "label": "English (US)" });
  const [creativity, setCreativity] = useState({ value: 'regular', label: 'Regular' });
  const [productName, setProductName] = useState('');
  const [audience, setAudience] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [quantity, setQuantity] = useState(4);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const characterLimits = {
    productName: 100,
    audience: 100,
    productDescription: 400,
  };

  const validateInputs = () => {
    const newErrors = {};
    if (productName.length > characterLimits.productName) {
      newErrors.productName = `Product name must be less than ${characterLimits.productName} characters.`;
    }
    if (audience.length > characterLimits.audience) {
      newErrors.audience = `Audience must be less than ${characterLimits.audience} characters.`;
    }
    if (productDescription.length > characterLimits.productDescription) {
      newErrors.productDescription = `Product description must be less than ${characterLimits.productDescription} characters.`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleGenerate = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;

    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 100);
      const res = await axiosInstance.post('/connect/generate-product-description/', {
        language: selectedLanguage ? selectedLanguage.value : '',
        creativity: creativity.value,
        product_name: productName,
        audience,
        short_description: productDescription
      });
      if (res) {
        localStorage.removeItem('productDescriptions');
        setProgress(0);
        navigate('/generated-product-description', { state: { id: res.data.id } });
      }
    } catch (error) {
      console.error('Error generating descriptions:', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {isLoading && <Loading progress={progress} message="Generating descriptions, please wait..." />}
      <PageHeader title="Product Descriptions" subtitle="Write the description about your product and why it's worth purchasing." />
      <div className="w-full max-w-2xl pb-10">
        <div className="bg-white mx-4 md:mx-0 rounded-lg shadow-lg p-8">
          <form onSubmit={handleGenerate}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Language</label>
                <Select
                  options={languages}
                  value={selectedLanguage}
                  onChange={setSelectedLanguage}
                  placeholder="Select a language"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Creativity</label>
                <Select
                  options={[
                    { value: 'regular', label: 'Regular' },
                    { value: 'high', label: 'High' },
                  ]}
                  value={creativity}
                  onChange={setCreativity}
                  placeholder="Select creativity level"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Product Name</label>
                <input
                  type="text"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  placeholder="Enter product name"
                  className={`w-full px-4 py-2 text-gray-600 border ${errors.productName ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                  required
                />
                {errors.productName && <p className="text-red-500 text-xs mt-2">{errors.productName}</p>}
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Audience</label>
                <input
                  type="text"
                  value={audience}
                  onChange={(e) => setAudience(e.target.value)}
                  placeholder="Enter audience e.g. Men"
                  className={`w-full px-4 py-2 text-gray-600 border ${errors.audience ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                  required
                />
                {errors.audience && <p className="text-red-500 text-xs mt-2">{errors.audience}</p>}
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Product Description</label>
              <textarea
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                placeholder="Describe your product in a few words. e.g. A stylish and comfortable pair of running shoes designed for optimal performance." 
                className={`w-full px-4 py-2 text-gray-600 border ${errors.productDescription ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                rows="4"
                maxLength="400"
                required
              />
              {errors.productDescription && <p className="text-red-500 text-xs mt-2">{errors.productDescription}</p>}
              <div className="text-right text-gray-500 text-sm mt-1">
                {productDescription.length}/400
              </div>
            </div> 
            <button type="submit" className="w-full py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800 transition duration-300">Generate</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductDescriptionGenerator;
