import React from 'react';
import { Disclosure } from '@headlessui/react';
import { FaPlus, FaMinus } from 'react-icons/fa6';
import { motion } from 'framer-motion';

const faqs = [
  {
    question: 'What is the Article Generator?',
    answer: `
      The Article Generator is a powerful AI tool designed to turn a title and an outline into a fully SEO-optimized and long article. 
      With this AI editor, you can effortlessly generate high-quality content that ranks well on search engines. 
      Simply provide a title and an outline, and our AI will handle the rest, ensuring that your article is both informative and engaging. 
      Whether you need content for your blog, website, or marketing materials, the Article Generator is your go-to solution.
    `,
  },
  {
    question: 'How does the Blog Post Writer work?',
    answer: `
      Our Blog Post Writer allows you to quickly write quality blog posts with a user-friendly drag-and-drop interface. 
      This tool simplifies the content creation process, enabling you to focus on your ideas while the AI handles the structure and flow. 
      You can add different sections, images, and formatting elements with ease, ensuring that your blog posts are not only well-written but also visually appealing. 
      It's perfect for bloggers, marketers, and businesses looking to maintain an active online presence without the hassle of writing from scratch.
    `,
  },
  {
    question: 'What does the Content Rewriter do?',
    answer: `
      The Content Rewriter is designed to help you rewrite long-form content or any text in seconds, making it unique and plagiarism-free. 
      This tool is ideal for updating old content, repurposing articles, or ensuring that your content stands out from the competition. 
      With advanced algorithms, the Content Rewriter maintains the original meaning and tone while delivering fresh and engaging text. 
      It's a must-have for content creators, marketers, and anyone looking to improve their writing.
    `,
  },
  {
    question: 'How can I use the Image Generator?',
    answer: `
      The Image Generator allows you to create stunning images from text descriptions using AI-powered technology. 
      Simply describe the image you want, and our AI will generate a high-quality visual that matches your description. 
      This tool is perfect for creating custom graphics, social media posts, marketing materials, and more. 
      With the Image Generator, you can bring your ideas to life and make your content more visually engaging.
    `,
  },
  {
    question: 'What is the Ecommerce Product Description tool?',
    answer: `
      The Ecommerce Product Description tool helps you generate compelling product descriptions for your ecommerce store. 
      By leveraging AI, this tool creates descriptions that highlight the key features and benefits of your products, making them more appealing to potential customers. 
      You can save time and effort while ensuring that your product listings are optimized for search engines and conversion. 
      It's an essential tool for ecommerce businesses looking to improve their product pages and boost sales.
    `,
  },
  {
    question: 'How do I create Social Media Posts?',
    answer: `
      Our Social Media Post tool enables you to create engaging social media posts to captivate your audience. 
      With AI-driven suggestions, you can generate content that resonates with your followers and drives engagement. 
      Whether you're promoting a product, sharing news, or simply engaging with your community, this tool helps you craft the perfect message. 
      Additionally, it provides insights into the best times to post and the most effective hashtags to use, ensuring that your social media strategy is always on point.
    `,
  },
];

const FAQ = () => {
  return (
    <div className="bg-gray-50 pt-12 pb-24">
      <div className="w-full md:max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.h2 
          className="text-4xl font-extrabold text-gray-900 text-center mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          Frequently Asked Questions
        </motion.h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Disclosure defaultOpen={index === 0}>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between items-center w-full px-4 py-5 text-left text-lg font-medium text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75 shadow-md">
                      <span>{faq.question}</span>
                      <div className="flex items-center">
                        {open ? (
                          <FaMinus className="w-5 h-5 text-gray-500" />
                        ) : (
                          <FaPlus className="w-5 h-5 text-gray-500" />
                        )}
                      </div>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2 mt-2 shadow-xl text-gray-700 bg-white rounded-lg">
                      {faq.answer.split('\n').map((line, i) => (
                        <p key={i} className="mb-2">{line}</p>
                      ))}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
