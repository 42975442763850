import React, { useState, useEffect } from 'react';
import PageHeader from '../../Components/PageHeader';
import StepNavigation from './StepNavigation';
import Modal from '../../Components/Modal';
import "../../../style/scroll.css";
import axiosInstance from '../../../Common/axiosInstance';
import Loading from '../../Components/Loading';

const StepTitle = ({ nextStep, prevStep, handleChange, values, currentStep, steps }) => {
  const [error, setError] = useState(false);
  const [remainingChars, setRemainingChars] = useState(100); // Assuming 100 characters max

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generatedTitles, setGeneratedTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const savedTitles = localStorage.getItem('generatedTitles');
    if (savedTitles) {
      try {
        setGeneratedTitles(JSON.parse(savedTitles));
      } catch (e) {
        console.error('Error parsing saved titles from localStorage:', e);
      }
    }
  }, []);

  const handleNextStep = () => {
    if (values.title.trim() === '' || values.title.length > 100) {
      setError(true);
    } else {
      setError(false);
      nextStep();
    }
  };

  const handleTitleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 100) {
      handleChange('title', value);
      setRemainingChars(100 - value.length);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
            if (prevProgress >= 100) {
                clearInterval(fakeProgress);
                return 100;
            }
            return prevProgress + 3;
        });
      }, 100);
      const response = await axiosInstance.post('/connect/generate-titles/', {
        language: values.language,
        keyword: values.keyword,
        creativity: values.creativity,
        description: values.description
      });
      
      const getTitles = response.data;
      console.log(getTitles.blog_titles);
      
      const titles = getTitles.blog_titles
      
      setGeneratedTitles(titles);
      localStorage.setItem('generatedTitles', JSON.stringify(titles));
      setIsModalOpen(true);
      setProgress(0)
    } catch (error) {
      console.error('Failed to generate titles', error);
    }
    setIsLoading(false);
  };

  const handleSelectTitle = (title) => {
    handleChange('title', title);
    setIsModalOpen(false);
  };

  const handleRegenerate = () => {
    setGeneratedTitles([]);
    localStorage.removeItem('generatedTitles');
    handleGenerate();
  };

  return (
    <div className="w-full md:max-w-xl pb-10 md:pb-40 px-2 md:px-0">
      {isLoading && <Loading progress={progress} message="Generating titles, please wait..." />}
      <PageHeader title="Blog Post Generator" subtitle="Generate a post title or write your own" />
      <StepNavigation currentStep={currentStep} steps={steps} />
      <div className="bg-white rounded-lg shadow-lg p-8">
        <div className="grid grid-cols-1 justify-center md:grid-cols-12 gap-4 mt-4">
          <div className="md:col-span-8">
            <label className="block text-gray-700 font-semibold mb-2">Post Title</label>
            <textarea
              className="w-full p-2 text-gray-600 border border-gray-300 rounded-lg"
              value={values.title}
              onChange={handleTitleChange}
              placeholder="e.g. A blog article explaining how copywriting can drive more traffic to your website."
            />
            {error && (
              <div className="text-red-500 mt-2">
                <small>
                  {values.title.length > 100 
                    ? `Title cannot exceed 100 characters.`
                    : `Title is required.`}
                </small>
              </div>
            )}
            <div className="text-gray-500 mt-1">
              <small>{remainingChars} characters remaining.</small>
            </div>
          </div>
          <div className="md:col-span-4 flex flex-col items-center justify-center space-y-4">
            <button
              className="md:mt-8 px-4 py-2 bg-white border border-primary-600 text-primary-600 hover:bg-gradient-to-r hover:from-primary-500 hover:to-primary-700 hover:text-white rounded-lg"
              onClick={handleRegenerate}
            >
              {generatedTitles.length > 0 ? 'Regenerate Titles' : 'Generate Titles'}
            </button>
            {generatedTitles.length > 0 && (
              <button
                className="px-10 py-2 bg-gray-400 border border-gray-400 text-white rounded-lg hover:bg-gray-600"
                onClick={() => setIsModalOpen(true)}
              >
                View Titles
              </button>
            )}
          </div>
        </div>
        <div className="mt-12 flex justify-between">
          <button
            className="px-4 py-2 bg-gradient-to-r from-gray-500 to-gray-700 text-white rounded-lg"
            onClick={prevStep}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg"
            onClick={handleNextStep}
          >
            Next
          </button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4">Generated Titles</h2>
        <div className="space-y-4 max-h-96 overflow-y-auto py-2 scrollbar-thin scrollbar-thumb-primary-500 scrollbar-track-gray-200 rounded-lg">
          {generatedTitles.map((title, index) => (
            <div key={index} className="flex justify-between items-center border p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
              <span className="text-gray-700">{title}</span>
              <button
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-700"
                onClick={() => handleSelectTitle(title)}
              >
                Select
              </button>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default StepTitle;
