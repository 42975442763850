import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetPassword } from '../../../redux/actions/authActions';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const { loading, error, message } = authState;

  const characterLimit = 100;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation
    if (!email) {
      setValidationError("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setValidationError("Please enter a valid email address.");
      return;
    } else if (email.length > characterLimit) {
      setValidationError(`Email must be less than ${characterLimit} characters.`);
      return;
    } else {
      setValidationError('');
    }

    setIsSubmitting(true);

    const res = await dispatch(resetPassword({ email: email }));

    setIsSubmitting(false);
    if (res) {
      setEmail('');
      setSubmitted(true);
      setNotFound(false);
    } else {
      setSubmitted(false);
      setNotFound(true);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-primary-50 to-gray-50 py-12">
      <div className="max-w-md w-full bg-white p-8 rounded-xl shadow-lg">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">
            Forgot Password
          </h2>
          <p className="text-gray-600">
            Enter your email to receive a password reset link.
          </p>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="appearance-none block w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-primary-500 focus:bg-white"
              maxLength={characterLimit}
            />
            {validationError && (
              <p className="text-red-500 mt-2 text-sm">{validationError}</p>
            )}
          </div>
          <div>
            <button
              type="submit"
              className={`group relative w-full flex justify-center py-3 px-4 border border-transparent font-medium rounded-full text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary-500 hover:bg-primary-600'}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span>Submitting...</span>
              ) : (
                <>
                  <svg
                    className="w-6 h-6 mr-2"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                    <circle cx="8.5" cy="7" r="4" />
                    <path d="M20 8v6M23 11h-6" />
                  </svg>
                  Send Reset Link
                </>
              )}
            </button>
          </div>
        </form>
        <div className="text-center mt-6">
          <p className="font-bold text-gray-500">OR</p>
        </div>
        <div className="w-full flex-1 mt-8">
          {loading && <p className="text-center text-gray-600">Loading...</p>}
          {error && <p className="text-center text-red-500">{error}</p>}
          {message && <p className="text-center text-green-500">{message}</p>}
          {submitted && !error && !loading && (
            <p className="text-center text-green-500">A reset link has been sent to your email.</p>
          )}
          {notFound && !error && !loading && (
            <p className="text-center text-red-500">Email is not found.</p>
          )}
        </div>
        <div className="mt-6 text-center">
          <Link to="/signin" className="text-sm font-medium text-primary-500 hover:text-primary-700">
            Back to Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
