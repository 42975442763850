export const ARTICLE_GENERATE_LOADING = 'ARTICLE_GENERATE_LOADING'
export const ARTICLE_GENERATE_SUCCESS = 'ARTICLE_GENERATE_SUCCESS'
export const ARTICLE_GENERATE_FAIL = 'ARTICLE_GENERATE_FAIL'

export const GET_ARTICLES_LOADING = 'GET_ARTICLES_LOADING'
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS'
export const GET_ARTICLES_FAIL = 'GET_ARTICLES_FAIL'

export const GET_ARTICLE_LOADING = 'GET_ARTICLE_LOADING'
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS'
export const GET_ARTICLE_FAIL = 'GET_ARTICLE_FAIL'

export const UPDATE_ARTICLE_LOADING = 'UPDATE_ARTICLE_LOADING'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAIL = 'UPDATE_ARTICLE_FAIL'

export const GET_BLOGS_LOADING = 'GET_BLOGS_LOADING'
export const GET_BLOGS_SUCCESS = 'GET_BLOGS_SUCCESS'
export const GET_BLOGS_FAIL = 'GET_BLOGS_FAIL'

export const GET_BLOG_LOADING = 'GET_BLOG_LOADING'
export const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS'
export const GET_BLOG_FAIL = 'GET_BLOG_FAIL'

export const UPDATE_BLOG_LOADING = 'UPDATE_BLOG_LOADING'
export const UPDATE_BLOG_SUCCESS = 'UPDATE_BLOG_SUCCESS'
export const UPDATE_BLOG_FAIL = 'UPDATE_BLOG_FAIL'

export const GET_REWRITTEN_CONTENTS_LOADING = 'GET_REWRITTEN_CONTENTS_LOADING'
export const GET_REWRITTEN_CONTENTS_SUCCESS = 'GET_REWRITTEN_CONTENTS_SUCCESS'
export const GET_REWRITTEN_CONTENTS_FAIL = 'GET_REWRITTEN_CONTENTS_FAIL'

export const GET_REWRITTEN_CONTENT_LOADING = 'GET_REWRITTEN_CONTENT_LOADING'
export const GET_REWRITTEN_CONTENT_SUCCESS = 'GET_REWRITTEN_CONTENT_SUCCESS'
export const GET_REWRITTEN_CONTENT_FAIL = 'GET_REWRITTEN_CONTENT_FAIL'

export const UPDATE_REWRITTEN_CONTENT_LOADING = 'UPDATE_REWRITTEN_CONTENT_LOADING'
export const UPDATE_REWRITTEN_CONTENT_SUCCESS = 'UPDATE_REWRITTEN_CONTENT_SUCCESS'
export const UPDATE_REWRITTEN_CONTENT_FAIL = 'UPDATE_REWRITTEN_CONTENT_FAIL'

export const GET_PRODUCT_DESCRIPTIONS_LOADING = 'GET_PRODUCT_DESCRIPTIONS_LOADING'
export const GET_PRODUCT_DESCRIPTIONS_SUCCESS = 'GET_PRODUCT_DESCRIPTIONS_SUCCESS'
export const GET_PRODUCT_DESCRIPTIONS_FAIL = 'GET_PRODUCT_DESCRIPTIONS_FAIL'

export const GET_PRODUCT_DESCRIPTION_LOADING = 'GET_PRODUCT_DESCRIPTION_LOADING'
export const GET_PRODUCT_DESCRIPTION_SUCCESS = 'GET_PRODUCT_DESCRIPTION_SUCCESS'
export const GET_PRODUCT_DESCRIPTION_FAIL = 'GET_PRODUCT_DESCRIPTION_FAIL'

export const UPDATE_PRODUCT_DESCRIPTION_LOADING = 'UPDATE_PRODUCT_DESCRIPTION_LOADING'
export const UPDATE_PRODUCT_DESCRIPTION_SUCCESS = 'UPDATE_PRODUCT_DESCRIPTION_SUCCESS'
export const UPDATE_PRODUCT_DESCRIPTION_FAIL = 'UPDATE_PRODUCT_DESCRIPTION_FAIL'

export const GET_SOCIAL_MEDIA_POSTS_LOADING = 'GET_SOCIAL_MEDIA_POSTS_LOADING'
export const GET_SOCIAL_MEDIA_POSTS_SUCCESS = 'GET_SOCIAL_MEDIA_POSTS_SUCCESS'
export const GET_SOCIAL_MEDIA_POSTS_FAIL = 'GET_SOCIAL_MEDIA_POSTS_FAIL'

export const GET_SOCIAL_MEDIA_POST_LOADING = 'GET_SOCIAL_MEDIA_POST_LOADING'
export const GET_SOCIAL_MEDIA_POST_SUCCESS = 'GET_SOCIAL_MEDIA_POST_SUCCESS'
export const GET_SOCIAL_MEDIA_POST_FAIL = 'GET_SOCIAL_MEDIA_POST_FAIL'

export const UPDATE_SOCIAL_MEDIA_POST_LOADING = 'UPDATE_SOCIAL_MEDIA_POST_LOADING'
export const UPDATE_SOCIAL_MEDIA_POST_SUCCESS = 'UPDATE_SOCIAL_MEDIA_POST_SUCCESS'
export const UPDATE_SOCIAL_MEDIA_POST_FAIL = 'UPDATE_SOCIAL_MEDIA_POST_FAIL'

export const GET_GENERATED_IMAGES_LOADING = 'GET_GENERATED_IMAGES_LOADING'
export const GET_GENERATED_IMAGES_SUCCESS = 'GET_GENERATED_IMAGES_SUCCESS'
export const GET_GENERATED_IMAGES_FAIL = 'GET_GENERATED_IMAGES_FAIL'

export const GET_GENERATED_IMAGE_LOADING = 'GET_GENERATED_IMAGE_LOADING'
export const GET_GENERATED_IMAGE_SUCCESS = 'GET_GENERATED_IMAGE_SUCCESS'
export const GET_GENERATED_IMAGE_FAIL = 'GET_GENERATED_IMAGE_FAIL'