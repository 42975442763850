import React, { useEffect, useState } from 'react';
import StepDetails from './StepDetails';
import StepTitle from './StepTitle';
import StepIntro from './StepIntro';
import StepOutline from './StepOutline';
import StepContent from './StepContent';

const BlogPostGenerator = () => {
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    language: 'en',
    creativity: 'regular',
    description: '',
    keyword: '',
    title: '',
    intro: '',
    outline: '',
    content: '',
  });

  const steps = [
    'Details',
    'Title',
    'Intro',
    'Outline',
    'Content'
  ];

  useEffect(() => {
    localStorage.removeItem('generatedTitles');
    localStorage.removeItem('generatedIntros');
    localStorage.removeItem('generatedOutlines');
  }, []);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (input, value) => {
    setFormValues({ ...formValues, [input]: value });
  };

  return (
    <div className="container flex flex-col items-center justify-center bg-gray-100">
        {step === 1 && (
          <StepDetails
            nextStep={nextStep}
            handleChange={handleChange}
            values={formValues}
            currentStep={step}
            steps={steps}
          />
        )}
        {step === 2 && (
          <StepTitle
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formValues}
            currentStep={step}
            steps={steps}
          />
        )}
        {step === 3 && (
          <StepIntro
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formValues}
            currentStep={step}
            steps={steps}
          />
        )}
        {step === 4 && (
          <StepOutline
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={formValues}
            currentStep={step}
            steps={steps}
          />
        )}
        {step === 5 && (
          <StepContent
            prevStep={prevStep}
            handleChange={handleChange}
            values={formValues}
            currentStep={step}
            steps={steps}
          />
        )}
    </div>
  );
};

export default BlogPostGenerator;
