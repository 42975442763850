import React from 'react';
import { motion } from 'framer-motion';

const CallToAction = () => {
  return (
    <div className='bg-gray-50 pb-24'>
      <motion.div 
        className="bg-primary-50 py-16 mx-4 md:mx-12 rounded-xl shadow-md"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.h2 
            className="text-xl font-semibold text-primary-700 tracking-wide uppercase"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.7 }}
          >
            Enhance Your Business Efficiency
          </motion.h2>
          <motion.p 
            className="mt-2 text-4xl font-extrabold text-gray-900 sm:text-5xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.7 }}
          >
            Unlock the Power of AI Today
          </motion.p>
          <motion.p 
            className="mt-4 text-lg leading-6 text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.7 }}
          >
            Experience the future of AI-driven content creation and business automation with Connect AI Box.
          </motion.p>
          <motion.div 
            className="mt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.9, duration: 0.7 }}
          >
            <a
              href="#"
              className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full bg-gradient-to-r from-primary-500 to-primary-600 text-white hover:from-primary-600 hover:to-primary-700 transition duration-300"
            >
              Start Your Free Trial
            </a>
          </motion.div>
          <motion.div 
            className="mt-4 flex justify-center font-semibold space-x-6 text-gray-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.1, duration: 0.7 }}
          >
            <span>10+ tools to explore</span>
            <span>•</span>
            <span>No credit card required</span>
            <span>•</span>
            <span>Cancel anytime</span>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default CallToAction;
