import React from 'react';
import { FaRobot } from 'react-icons/fa';
import { FaArrowRight } from "react-icons/fa6";
import { motion } from 'framer-motion';

const HomePageHero = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-primary-50 px-4 py-8 md:p-0 to-gray-50 text-gray-800">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        className="bg-primary-200 text-green-600 py-2 px-6 rounded-full inline-flex items-center font-bold mb-6 shadow-lg"
      >
        <span className="bg-gray-100 rounded-full px-2 py-1 mr-2 shadow-xl">
          <FaRobot className="" />
        </span>
        Discover the Future of Content Creation with Connect AI Box!
      </motion.div>
      <motion.h1
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7, delay: 0.2 }}
        className="text-5xl md:text-6xl lg:text-7xl font-extrabold mb-6 text-center leading-tight"
      >
        Transform Your Content Creation <br /> — <span className='text-gray-500'>Effortlessly!</span>
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.7, delay: 0.4 }}
        className="text-lg md:text-xl text-gray-700 mb-10 max-w-2xl text-center"
      >
        Connect AI Box's innovative platform leverages cutting-edge AI to enhance productivity, streamline content creation, and boost engagement for businesses of all sizes.
      </motion.p>
      <motion.button
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7, delay: 0.6 }}
        className="flex py-4 px-10 rounded-full text-lg bg-gradient-to-r from-primary-500 to-primary-600 text-white hover:from-primary-600 hover:to-primary-700 transition duration-300 font-semibold shadow-lg"
      >
        Get Started for FREE <FaArrowRight size={20} className='mt-1 ml-2' />
      </motion.button>
    </div>
  );
};

export default HomePageHero;
