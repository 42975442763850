import React from 'react';
import ContactHero from './Contact/ContactHero';
import ContactFAQ from './Contact/FaqContact';
import ContactInfo from './Contact/ContactInfo';

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactInfo />
      <ContactFAQ />
    </>
  );
};

export default Contact;
