import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Frontend/page/Home';
import About from '../Frontend/page/About';
import Services from '../Frontend/page/Service';
import Contact from '../Frontend/page/Contact';
import SigninPage from '../Frontend/page/Auth/Signin'; 
import ForgetPassword from '../Frontend/page/Auth/ForgetPassword'; 
import EmailVerification from '../Frontend/page/Auth/EmailVerification'; 
import PrivacyPolicy from '../Frontend/page/PrivacyPolicy';
import TermsAndConditions from '../Frontend/page/TermsAndConditions ';
import SignupPage from '../Frontend/page/Auth/Signup';
import CreateNewPassword from '../Frontend/page/Auth/CreateNewPassword';
import DashboardHome from '../Dashboard/DashboardHome';
import FrontendLayout from '../Layouts/FrontendLayout';
import DashboardLayout from '../Layouts/DashboardLayout';
import Team from '../Dashboard/Page/Team';
import AddTeamMember from '../Dashboard/Page/Team/AddTeamMember';
import Profile from '../Dashboard/Page/Profile';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import EmailVerified from '../Frontend/page/Auth/EmailVerified';
import ArticleGenerator from '../Dashboard/Page/ArticleGenerator/ArticleGenerator';
import Articles from '../Dashboard/Page/ArticleGenerator/Articles';
import Article from '../Dashboard/Page/ArticleGenerator/Article';
import BlogPostGenerator from '../Dashboard/Page/BlogPostGenerator/BlogPostGenerator';
import Blogs from '../Dashboard/Page/BlogPostGenerator/Blogs';
import Blog from '../Dashboard/Page/BlogPostGenerator/Blog';
import RewriteContentGenerator from '../Dashboard/Page/ContentGenerator/RewriteContentGenerator';
import RewrittenContents from '../Dashboard/Page/ContentGenerator/RewriteContents';
import RewrittenContent from '../Dashboard/Page/ContentGenerator/RewrittenContent';
import ProductDescriptionGenerator from '../Dashboard/Page/ProductDescription/ProductDescriptionGenerator';
import ProductDescriptions from '../Dashboard/Page/ProductDescription/ProductDescriptions';
import ProductDescription from '../Dashboard/Page/ProductDescription/ProductDescription';
import SocialMediaPostGenerator from '../Dashboard/Page/SocialMediaPostGenerator/SocialMediaPostGenerator';
import SocailMediaPost from '../Dashboard/Page/SocialMediaPostGenerator/SocialMediaPost';
import SocialMediaPosts from '../Dashboard/Page/SocialMediaPostGenerator/SocialMediaPosts';
import ImageGenerator from '../Dashboard/Page/ImageGenerator/ImageGenerator';
import GeneratedImages from '../Dashboard/Page/ImageGenerator/GeneratedImages';
import GeneratedImage from '../Dashboard/Page/ImageGenerator/GeneratedImage';

const RouteList = () => {
  return (
    <Routes>
      <Route element={<FrontendLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/service" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/term-and-condition" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<ProtectedRoute element={<DashboardHome />} />} />
        <Route path="/team" element={<ProtectedRoute element={<Team />} />} />
        <Route path="/article-generator" element={<ProtectedRoute element={<ArticleGenerator />} />} />
        <Route path="/articles" element={<ProtectedRoute element={<Articles />} />} />
        <Route path="/generated-article" element={<ProtectedRoute element={<Article />} />} />
        <Route path="/blog-post-generator" element={<ProtectedRoute element={<BlogPostGenerator />} />} />
        <Route path="/blogs" element={<ProtectedRoute element={<Blogs />} />} />
        <Route path="/generated-blog" element={<ProtectedRoute element={<Blog />} />} />
        <Route path="/rewrite-conetent-generator" element={<ProtectedRoute element={<RewriteContentGenerator />} />} />
        <Route path="/rewrite-contents" element={<ProtectedRoute element={<RewrittenContents />} />} />
        <Route path="/generated-rewrite-content" element={<ProtectedRoute element={<RewrittenContent />} />} />
        <Route path="/product-description-generator" element={<ProtectedRoute element={<ProductDescriptionGenerator />} />} />
        <Route path="/product-descriptions" element={<ProtectedRoute element={<ProductDescriptions />} />} />
        <Route path="/generated-product-description" element={<ProtectedRoute element={<ProductDescription />} />} />
        <Route path="/social-media-post-generator" element={<ProtectedRoute element={<SocialMediaPostGenerator />} />} />
        <Route path="/social-media-posts" element={<ProtectedRoute element={<SocialMediaPosts />} />} />
        <Route path="/generated-social-media-post" element={<ProtectedRoute element={<SocailMediaPost />} />} />
        <Route path="/image-generator" element={<ProtectedRoute element={<ImageGenerator />} />} />
        <Route path="/generated-images" element={<ProtectedRoute element={<GeneratedImages />} />} />
        <Route path="/generated-image" element={<ProtectedRoute element={<GeneratedImage />} />} />
        <Route path="/add-member" element={<ProtectedRoute element={<AddTeamMember />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
      </Route>
    </Routes>
  );
};

export default RouteList;
