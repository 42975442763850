import React from 'react';
import { Link } from 'react-router-dom';

const Homelayout = () => {
  return (
    <div className="bg-gradient-to-b from-white to-gray-50 py-16 md:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-24 items-center">
          <div>
            <h2 className="text-4xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-4">
              Building Bridges Between You And Your Customers
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Harness the power of AI to enhance your business processes. Connect AI Box offers innovative solutions to streamline content creation, boost engagement, and drive growth.
            </p>
            <Link className="bg-primary-600 text-white py-3 px-6 rounded-full text-lg hover:bg-primary-700 transition duration-300" to="/signup">
              Get Started
            </Link>
            <div className="flex items-center mt-4">
              <button className="bg-white text-primary-600 border border-primary-600 py-2 px-4 rounded-full text-md hover:bg-gray-100 transition duration-300">
                Manage your business. The professional way
              </button>
              <div className="flex items-center space-x-2 ml-4">
                <span className="inline-block w-8 h-8 bg-primary-500 rounded-full flex items-center justify-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <span className="inline-block w-8 h-8 bg-primary-500 rounded-full flex items-center justify-center text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4 text-center">
              <div>
                <p className="text-2xl font-bold text-gray-900">450+</p>
                <p className="font-semibold text-sm text-gray-600">Projects Live</p>
              </div>
              <div>
                <p className="text-2xl font-bold text-gray-900">150+</p>
                <p className="font-semibold text-sm text-gray-600">Active Users</p>
              </div>
              <div>
                <p className="text-2xl font-bold text-gray-900">120+</p>
                <p className="font-semibold text-sm text-gray-600">Lives Enhanced</p>
              </div>
              <div>
                <p className="text-2xl font-bold text-gray-900">600+</p>
                <p className="font-semibold text-sm text-gray-600">Satisfied Clients</p>
              </div>
            </div>
          </div>
          <div className="relative">
            <img src="https://images.pexels.com/photos/3184465/pexels-photo-3184465.jpeg" alt="Team working together" className="rounded-lg shadow-lg" />
            <div className="absolute top-0 left-0 bg-white font-semibold text-primary-600 py-2 px-4 rounded-br-lg shadow-lg">
              500+ RATINGS
              <br />
              <a href="#" className="text-gray-600 ">Fully Satisfied our clients </a>
            </div>
          </div>
        </div>
        <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-gradient-to-r from-green-50 to-primary-50 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg text-center font-semibold text-gray-900 mb-3">AI-Powered Automation</h3>
            <p className="text-gray-700 text-center">
              Leverage cutting-edge AI to automate repetitive tasks, enhance productivity, and streamline your workflows.
            </p>
          </div>
          <div className="bg-gradient-to-r from-green-50 to-primary-50 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg text-center font-semibold text-gray-900 mb-3">User-Friendly Interface</h3>
            <p className="text-gray-700 text-center">
            An intuitive and user-friendly interface that makes it easy to get started and utilize the full potential of our AI solutions.
            </p>
          </div>
          <div className="bg-gradient-to-r from-green-50 to-primary-50 p-6 rounded-lg shadow-lg">
            <h3 className="text-lg text-center font-semibold text-gray-900 mb-3">Digital Transformation</h3>
            <p className="text-gray-700 text-center">
              Integrate cutting-edge AI technology into your business operations, driving innovation and efficiency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homelayout;
