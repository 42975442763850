import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PageHeader from '../../Components/PageHeader';
import axiosInstance from '../../../Common/axiosInstance';
import Loading from '../../Components/Loading';
import ModalImage from '../../Components/ModalImage';
import engines from '../../../data/engines.json';
import styles from '../../../data/styles.json';
import mediums from '../../../data/mediums.json';
import artists from '../../../data/artists.json';
import moods from '../../../data/moods.json';
import { FaChevronCircleLeft } from "react-icons/fa";
import { getGeneratedImages } from '../../../redux/actions/connectActions';

const ImageGenerator = () => {
  const [imageDescription, setImageDescription] = useState('');
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedMedium, setSelectedMedium] = useState(null);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMood, setSelectedMood] = useState(null);
  const [details, setDetails] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const characterLimits = {
    imageDescription: 500,
    details: 100,
  };

  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await dispatch(getGeneratedImages());
            setImages(data);
            if(data.length >= 10) {
              setIsSubmitted(true);
            }
        } catch (error) {
            console.log(error.message);
        } 
    };

    fetchData();
  }, [dispatch]); 

  const validateInputs = () => {
    const newErrors = {};
    if (imageDescription.length > characterLimits.imageDescription) {
      newErrors.imageDescription = `Image description must be less than ${characterLimits.imageDescription} characters.`;
    }
    if (details.length > characterLimits.details) {
      newErrors.details = `Details must be less than ${characterLimits.details} characters.`;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  console.log('images:', images);
  

  const handleGenerate = async (event) => {
    event.preventDefault();
    if (!validateInputs()) return;
    if(images.length > 0) {
      localStorage.setItem('genImages', JSON.stringify(images));
    }

    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 100);
      const res = await axiosInstance.post('/connect/generate-image/', {
        image_description: imageDescription,
        engine: selectedEngine ? selectedEngine.value : '',
        style: selectedStyle ? selectedStyle.value : '',
        medium: selectedMedium ? selectedMedium.value : '',
        artist: selectedArtist ? selectedArtist.value : '',
        mood: selectedMood ? selectedMood.value : '',
        details,
        quantity
      });
      if (res) {
        localStorage.removeItem('genImages');
        setProgress(0);
        navigate('/generated-images', { state: { id: res.data.id } });
      }
    } catch (error) {
      console.error('Error generating images:', error);
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    navigate('/generated-images');
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {isLoading && <Loading progress={progress} message="Generating images, please wait..." />}
      <PageHeader title="Image Generator" subtitle="Generate professional quality images from text for your website or blog." />
      <p className="-mt-4 mb-2 text-red-500">*You can generate a single image for testing purposes only!</p>
      <div className="w-full max-w-2xl pb-10">
        <div className="bg-white mx-4 md:mx-0 rounded-lg shadow-lg p-8">
          <form onSubmit={handleGenerate}>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Image Description*</label>
              <input
                type="text"
                value={imageDescription}
                onChange={(e) => setImageDescription(e.target.value)}
                placeholder="Photography of an astronaut driving a sports car on the moon"
                className={`w-full px-4 py-2 text-gray-600 border ${errors.imageDescription ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                required
              />
              {errors.imageDescription && <p className="text-red-500 text-xs mt-2">{errors.imageDescription}</p>}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Engine</label>
                <Select
                  options={engines}
                  value={selectedEngine}
                  onChange={setSelectedEngine}
                  placeholder="Select an engine"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Style</label>
                <Select
                  options={styles}
                  value={selectedStyle}
                  onChange={setSelectedStyle}
                  placeholder="Select a style"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Medium</label>
                <Select
                  options={mediums}
                  value={selectedMedium}
                  onChange={setSelectedMedium}
                  placeholder="Select a medium"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Artist</label>
                <Select
                  options={artists}
                  value={selectedArtist}
                  onChange={setSelectedArtist}
                  placeholder="Select an artist"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
              <div>
                <label className="block text-gray-700 mb-2">Mood</label>
                <Select
                  options={moods}
                  value={selectedMood}
                  onChange={setSelectedMood}
                  placeholder="Select a mood"
                  styles={{
                    control: (base) => ({
                      ...base,
                      borderRadius: '0.475rem',
                      padding: '3px',
                      borderColor: '#cbd5e0',
                      '&:hover': { borderColor: '#a0aec0' },
                      boxShadow: 'none'
                    }),
                    menu: (base) => ({
                      ...base,
                      borderRadius: '0.375rem',
                      borderColor: '#cbd5e0',
                    })
                  }}
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Details</label>
                <input
                  type="text"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                  placeholder="Enter any additional details"
                  className={`w-full px-4 py-2 text-gray-600 border ${errors.details ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-400`}
                />
                {errors.details && <p className="text-red-500 text-xs mt-2">{errors.details}</p>}
              </div>
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 mb-2">Quantity</label>
              <div className="flex items-center">
                <button
                  type="button"
                  className="px-3 py-1 bg-gray-300 text-gray-700 rounded-l-lg"
                  // onClick={() => setQuantity(Math.max(1, quantity - 1))}
                >
                  -
                </button>
                <input
                  type="number"
                  value={1}
                  onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                  className="w-16 text-center text-gray-600 border-t border-b border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-400"
                />
                <button
                  type="button"
                  className="px-3 py-1 bg-gray-300 text-gray-700 rounded-r-lg"
                  // onClick={() => setQuantity(quantity + 1)}
                >
                  +
                </button>
              </div>
            </div>
            <button type="submit" className="w-full py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg hover:from-primary-600 hover:to-primary-800 transition duration-300">Generate</button>
          </form>
        </div>
      </div>
      <ModalImage isOpen={isSubmitted}>
        <div className="p-6 text-center">
          <h2 className="text-2xl font-semibold mb-4">Image Generated</h2>
          <p>You have already generated an image for testing purposes. Please note that only one image can be created at this time.</p>
          <button
            onClick={handleCloseModal}
            className="mt-6 flex inline-flex items-center px-6 py-2 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-primary-500 to-primary-700 hover:from-primary-700 hover:to-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-transform transform hover:scale-105"
          >
            <FaChevronCircleLeft className="mr-2"/>Go Back
          </button>
        </div>
      </ModalImage>
    </div>
  );
};

export default ImageGenerator;
