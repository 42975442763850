import React from 'react';

const PageHeader = ({ title, subtitle }) => {

  return (
    <div className="text-center py-5">
        <h1 className="text-2xl md:text-4xl font-bold mb-2 text-gray-600">{title}</h1>
        <p className="text-gray-600 md:text-lg">{subtitle}</p>
    </div> 
  );
};

export default PageHeader;
