import axiosInstance from '../../Common/axiosInstance'; 
import {
    ARTICLE_GENERATE_LOADING,
    ARTICLE_GENERATE_SUCCESS,
    ARTICLE_GENERATE_FAIL,

    GET_ARTICLE_LOADING,
    GET_ARTICLE_SUCCESS,
    GET_ARTICLE_FAIL,

    GET_ARTICLES_LOADING,
    GET_ARTICLES_SUCCESS,
    GET_ARTICLES_FAIL,

    UPDATE_ARTICLE_LOADING,
    UPDATE_ARTICLE_SUCCESS,
    UPDATE_ARTICLE_FAIL,

    GET_BLOGS_LOADING,
    GET_BLOGS_SUCCESS,
    GET_BLOGS_FAIL,

    GET_BLOG_LOADING,
    GET_BLOG_SUCCESS,
    GET_BLOG_FAIL,

    UPDATE_BLOG_LOADING,
    UPDATE_BLOG_SUCCESS,
    UPDATE_BLOG_FAIL,

    GET_REWRITTEN_CONTENTS_LOADING,
    GET_REWRITTEN_CONTENTS_SUCCESS,
    GET_REWRITTEN_CONTENTS_FAIL,

    GET_REWRITTEN_CONTENT_LOADING,
    GET_REWRITTEN_CONTENT_SUCCESS,
    GET_REWRITTEN_CONTENT_FAIL,

    UPDATE_REWRITTEN_CONTENT_LOADING,
    UPDATE_REWRITTEN_CONTENT_SUCCESS,
    UPDATE_REWRITTEN_CONTENT_FAIL,

    GET_PRODUCT_DESCRIPTIONS_LOADING,
    GET_PRODUCT_DESCRIPTIONS_SUCCESS,
    GET_PRODUCT_DESCRIPTIONS_FAIL,

    GET_PRODUCT_DESCRIPTION_LOADING,
    GET_PRODUCT_DESCRIPTION_SUCCESS,
    GET_PRODUCT_DESCRIPTION_FAIL,

    UPDATE_PRODUCT_DESCRIPTION_LOADING,
    UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
    UPDATE_PRODUCT_DESCRIPTION_FAIL,

    GET_SOCIAL_MEDIA_POSTS_LOADING,
    GET_SOCIAL_MEDIA_POSTS_SUCCESS,
    GET_SOCIAL_MEDIA_POSTS_FAIL,

    GET_SOCIAL_MEDIA_POST_LOADING,
    GET_SOCIAL_MEDIA_POST_SUCCESS,
    GET_SOCIAL_MEDIA_POST_FAIL,

    UPDATE_SOCIAL_MEDIA_POST_LOADING,
    UPDATE_SOCIAL_MEDIA_POST_SUCCESS,
    UPDATE_SOCIAL_MEDIA_POST_FAIL,

    GET_GENERATED_IMAGES_LOADING,
    GET_GENERATED_IMAGES_SUCCESS,
    GET_GENERATED_IMAGES_FAIL,

    GET_GENERATED_IMAGE_LOADING,
    GET_GENERATED_IMAGE_SUCCESS,
    GET_GENERATED_IMAGE_FAIL,
} from '../constants/connectConstants';

export const generateArticle = (formData) => async (dispatch) => {
    try {
        dispatch({ type: ARTICLE_GENERATE_LOADING });
        const { data } = await axiosInstance.post('/connect/generate-article/', formData);

        dispatch({
            type: ARTICLE_GENERATE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: ARTICLE_GENERATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error; // Throw the error to handle it in the component
    }  
};

export const getArticle = (articleId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ARTICLE_LOADING });
        const { data } = await axiosInstance.get(`/connect/get-article/${articleId}/`);
        dispatch({
            type: GET_ARTICLE_SUCCESS,
            payload: data,
        }); 
        return data;
    } catch (error) {
        dispatch({
            type: GET_ARTICLE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getArticles = () => async (dispatch) => {
    try {
        dispatch({ type: GET_ARTICLES_LOADING });
        
        const cachedData = localStorage.getItem('articles');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: GET_ARTICLES_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('articles');
            }
        }

        const { data } = await axiosInstance.get('/connect/get-articles/');
        localStorage.setItem('articles', JSON.stringify({ data, timestamp: new Date().getTime() }));      
        
        dispatch({
            type: GET_ARTICLES_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_ARTICLES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const updateArticle = (articleId, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_ARTICLE_LOADING });
        const { data } = await axiosInstance.put(`/connect/update-article/${articleId}/`, formData);
        dispatch({
            type: UPDATE_ARTICLE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_ARTICLE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const getBlogs = () => async (dispatch) => {
    try {
        dispatch({ type: GET_BLOGS_LOADING });

        const cachedData = localStorage.getItem('blogs');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: GET_ARTICLES_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('blogs');
            }
        }

        const { data } = await axiosInstance.get('/connect/get-blogs/');
        localStorage.setItem('blogs', JSON.stringify({ data, timestamp: new Date().getTime() }));
        dispatch({
            type: GET_BLOGS_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_BLOGS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getBlog = (blogId) => async (dispatch) => {
    try {
        dispatch({ type: GET_BLOG_LOADING });
        const { data } = await axiosInstance.get(`/connect/get-blog/${blogId}/`);
        dispatch({
            type: GET_BLOG_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_BLOG_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const updateBlog = (blogId, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_BLOG_LOADING });
        const { data } = await axiosInstance.put(`/connect/update-blog/${blogId}/`, formData);
        dispatch({
            type: UPDATE_BLOG_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_BLOG_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const getRewrittenContents = () => async (dispatch) => {
    try {
        dispatch({ type: GET_REWRITTEN_CONTENTS_LOADING });
                        
        const cachedData = localStorage.getItem('rewrittenContents');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: GET_REWRITTEN_CONTENTS_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('rewrittenContents');
            }
        }

        const { data } = await axiosInstance.get('/connect/get-rewrites/');
        localStorage.setItem('rewrittenContents', JSON.stringify({ data, timestamp: new Date().getTime() }));      

        dispatch({
            type: GET_REWRITTEN_CONTENTS_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_REWRITTEN_CONTENTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getRewrittenContent = (contentId) => async (dispatch) => {
    try {
        dispatch({ type: GET_REWRITTEN_CONTENT_LOADING });
        const { data } = await axiosInstance.get(`/connect/get-rewrite/${contentId}/`);
        dispatch({
            type: GET_REWRITTEN_CONTENT_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_REWRITTEN_CONTENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const updateRewrittenContent = (contentId, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_REWRITTEN_CONTENT_LOADING });
        const { data } = await axiosInstance.put(`/connect/update-rewritten-content/${contentId}/`, formData);
        dispatch({
            type: UPDATE_REWRITTEN_CONTENT_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_REWRITTEN_CONTENT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const getProductDescriptions = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PRODUCT_DESCRIPTIONS_LOADING });

        const cachedData = localStorage.getItem('productDescriptions');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: GET_PRODUCT_DESCRIPTIONS_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('productDescriptions');
            }
        }

        const { data } = await axiosInstance.get('/connect/get-product-descriptions/');
        localStorage.setItem('productDescriptions', JSON.stringify({ data, timestamp: new Date().getTime() }));      
        
        dispatch({
            type: GET_PRODUCT_DESCRIPTIONS_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_DESCRIPTIONS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getProductDescription = (descriptionId) => async (dispatch) => {
    try {
        dispatch({ type: GET_PRODUCT_DESCRIPTION_LOADING });
        const { data } = await axiosInstance.get(`/connect/get-product-description/${descriptionId}/`);
        dispatch({
            type: GET_PRODUCT_DESCRIPTION_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_DESCRIPTION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const updateProductDescription = (descriptionId, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCT_DESCRIPTION_LOADING });
        const { data } = await axiosInstance.put(`/connect/update-product-description/${descriptionId}/`, formData);
        dispatch({
            type: UPDATE_PRODUCT_DESCRIPTION_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_DESCRIPTION_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const getSocialMediaPosts = () => async (dispatch) => {
    try {
        dispatch({ type: GET_SOCIAL_MEDIA_POSTS_LOADING });
        
        const cachedData = localStorage.getItem('smpots');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: GET_SOCIAL_MEDIA_POSTS_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('smpots');
            }
        }

        const { data } = await axiosInstance.get('/connect/get-social-media-posts/');
        localStorage.setItem('smpots', JSON.stringify({ data, timestamp: new Date().getTime() }));      

        dispatch({
            type: GET_SOCIAL_MEDIA_POSTS_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_SOCIAL_MEDIA_POSTS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getSocialMediaPost = (postId) => async (dispatch) => {
    try {
        dispatch({ type: GET_SOCIAL_MEDIA_POST_LOADING });
        const { data } = await axiosInstance.get(`/connect/get-social-media-post/${postId}/`);
        dispatch({
            type: GET_SOCIAL_MEDIA_POST_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_SOCIAL_MEDIA_POST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const updateSocialMediaPost = (postId, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SOCIAL_MEDIA_POST_LOADING });
        const { data } = await axiosInstance.put(`/connect/update-social-media-post/${postId}/`, formData);
        dispatch({
            type: UPDATE_SOCIAL_MEDIA_POST_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SOCIAL_MEDIA_POST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error;
    }
};

export const getGeneratedImages = () => async (dispatch) => {
    try {
        dispatch({ type: GET_GENERATED_IMAGES_LOADING });
                
        const cachedData = localStorage.getItem('genImages');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: GET_GENERATED_IMAGES_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('genImages');
            }
        }

        const { data } = await axiosInstance.get('/connect/get-generated-images/');
        localStorage.setItem('genImages', JSON.stringify({ data, timestamp: new Date().getTime() }));      

        dispatch({
            type: GET_GENERATED_IMAGES_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_GENERATED_IMAGES_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const getGeneratedImage = (imageId) => async (dispatch) => {
    try {
        dispatch({ type: GET_GENERATED_IMAGE_LOADING });
        const { data } = await axiosInstance.get(`/connect/get-generated-image/${imageId}/`);
        dispatch({
            type: GET_GENERATED_IMAGE_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: GET_GENERATED_IMAGE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};