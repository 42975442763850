import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaSearch, FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSocialMediaPosts } from '../../../redux/actions/connectActions';  // Make sure to implement this action

const SocialMediaPosts = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState('');
    const [socialMediaPosts, setSocialMediaPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const itemsPerPage = 10;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(getSocialMediaPosts());
                setSocialMediaPosts(data);
            } catch (error) {
                console.log(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const truncateContent = (content, wordLimit) => {
        const words = content.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : content;
    };

    const filteredContent = Array.isArray(socialMediaPosts) ? socialMediaPosts.filter((post) =>
        (post.company_name && post.company_name.toLowerCase().includes(filter.toLowerCase())) ||
        (post.generated_content && post.generated_content.toLowerCase().includes(filter.toLowerCase())) ||
        (post.created_at && post.created_at.includes(filter))
    ) : [];

    const offset = currentPage * itemsPerPage;
    const currentContent = filteredContent.slice(offset, offset + itemsPerPage);

    const handleView = (postId) => {
        navigate('/generated-social-media-post', { state: { id: postId } });
    };

    return (
        <div>
            <main className="p-4 min-h-screen">
                <div className="grid grid-cols-1 mt-6 mb-6">
                    <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Social Media Posts List</h1>
                    <p className="text-gray-500 dark:text-gray-300">
                        List of generated social media posts!
                    </p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                    <div className="flex justify-between items-center mb-6">
                        <div className="relative w-1/3">
                            <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
                            <input
                                type="text"
                                placeholder="Filter content"
                                className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />
                        </div>
                        <Link to="/social-media-post-generator" className="flex items-center px-1 md:px-8 py-2 text-white bg-primary-600 rounded-md shadow-md hover:from-primary-600 hover:to-indigo-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                            <FaFileAlt className="mr-2" />
                            Create Social Post
                        </Link>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white shadow-md rounded-lg">
                            <thead className="bg-gradient-to-r from-primary-100 to-indigo-100">
                                <tr>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Company Name</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Post Description</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentContent.length > 0 ? (
                                    currentContent.map((post, index) => (
                                        <tr key={index} className="border-b hover:bg-gray-50">
                                            <td className="py-3 px-5 text-sm text-gray-800">
                                                <button onClick={() => handleView(post.id)} className='text-primary-700 text-left font-semibold'>{post.company_name}</button>
                                            </td>
                                            <td className="py-3 px-5 text-sm text-gray-800">{truncateContent(post.post_content, 10)}</td>
                                            <td className="py-3 px-5 text-sm text-gray-800">{formatDate(post.created_at)}</td>
                                            <td className="py-3 px-5 text-sm">
                                                <button onClick={() => handleView(post.id)} className="px-4 py-1 text-primary-600 border border-primary-600 bg-white hover:bg-primary-600 hover:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">View</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4" className="py-10 text-center">
                                            <div className="flex flex-col items-center">
                                                <FaFileAlt className="mb-3 text-6xl text-gray-400" />
                                                <p className="text-lg font-medium text-gray-600">No Social Media Posts Found</p>
                                                <p className="text-sm text-gray-500">Try adding some posts!</p>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-6">
                        {currentContent.length > 0 && (
                            <ReactPaginate
                                previousLabel={'Previous'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                pageCount={Math.ceil(filteredContent.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'flex justify-center space-x-2'}
                                pageClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                previousClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                nextClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                breakClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                                activeClassName={'bg-primary-600 text-white'}
                            />
                        )}
                    </div>
                </div>
                <ToastContainer />
            </main>
        </div>
    );
};

export default SocialMediaPosts;
