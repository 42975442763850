import React, { useState, useEffect } from 'react';
import PageHeader from '../../Components/PageHeader';
import StepNavigation from './StepNavigation';
import Modal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import "../../../style/scroll.css";
import axiosInstance from '../../../Common/axiosInstance';

const StepIntro = ({ nextStep, prevStep, handleChange, values, currentStep, steps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generatedIntros, setGeneratedIntros] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [remainingChars, setRemainingChars] = useState(500); // Assuming 300 characters max

  useEffect(() => {
    const savedIntros = localStorage.getItem('generatedIntros');
    if (savedIntros) {
      try {
        setGeneratedIntros(JSON.parse(savedIntros));
      } catch (e) {
        console.error('Error parsing saved intros from localStorage:', e);
      }
    }
  }, []);

  const handleIntroChange = (e) => {
    const value = e.target.value;
    if (value.length <= 700) {
      handleChange('intro', value);
      setRemainingChars(500 - value.length);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 2;
        });
      }, 100);
      const response = await axiosInstance.post('/connect/generate-intros/', {
        language: values.language,
        keyword: values.keyword,
        creativity: values.creativity,
        description: values.description,
        title: values.title
      });

      const getintros = response.data; 
      const intros = getintros.blog_intros;
      setGeneratedIntros(intros);
      localStorage.setItem('generatedIntros', JSON.stringify(intros));
      setIsModalOpen(true);
      setProgress(0);
    } catch (error) {
      console.error('Failed to generate intros', error);
    }
    setIsLoading(false);
  };

  const handleSelectIntro = (intro) => {
    handleChange('intro', intro);
    setIsModalOpen(false);
  };

  const handleNextStep = () => {
    if (values.intro.trim() === '' || values.intro.length > 700) {
      setError(true);
    } else {
      setError(false);
      nextStep();
    }
  };

  const handleRegenerate = () => {
    setGeneratedIntros([]);
    localStorage.removeItem('generatedIntros');
    handleGenerate();
  };

  return (
    <div className="w-full md:max-w-xl pb-10 md:pb-40 px-2 md:px-0">
      {isLoading && <Loading progress={progress} message="Generating intros, please wait..." />}
      <PageHeader title="Blog Post Generator" subtitle="Generate an intro paragraph or write your own" />
      <StepNavigation currentStep={currentStep} steps={steps} />
      <div className="bg-white rounded-b-lg shadow-lg p-8">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-4">
          <div className="md:col-span-8">
            <label className="block text-gray-700 font-semibold mb-2">Intro Paragraph</label>
            <textarea
              className="w-full p-2 text-gray-600 border border-gray-300 rounded-lg"
              value={values.intro}
              onChange={handleIntroChange}
              placeholder="Enter the introduction for your blog post (max. 500 characters)"
              rows={4}
            />
            <div className="text-gray-500 mt-1">
              <small>{remainingChars} characters remaining.</small>
            </div>
          </div>
          <div className="md:col-span-4 flex flex-col items-center justify-center space-y-4">
            <button
              className="md:mt-8 px-3 py-2 bg-white border border-primary-600 text-primary-600 hover:bg-gradient-to-r hover:from-primary-500 hover:to-primary-700 hover:text-white rounded-lg"
              onClick={handleRegenerate}
            >
              {generatedIntros.length > 0 ? 'Regenerate Intros' : 'Generate Intros'}
            </button>
            {generatedIntros.length > 0 && (
              <button
                className="md:mt-2 px-8 py-2 bg-gray-400 border border-gray-400 text-white rounded-lg hover:bg-gray-600"
                onClick={() => setIsModalOpen(true)}
              >
                View Intros
              </button>
            )}
          </div>
        </div>
        {error && (
          <div className="text-red-500 mt-2">
            <small>
              {values.intro.length > 700 
                ? `Intro paragraph cannot exceed 500 characters.`
                : `Intro paragraph is required.`}
            </small>
          </div>
        )}
        <div className="mt-6 flex justify-between">
          <button
            className="px-4 py-2 bg-gradient-to-r from-gray-500 to-gray-700 text-white rounded-lg"
            onClick={prevStep}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg"
            onClick={handleNextStep}
            disabled={values.intro.trim() === '' || values.intro.length > 700}
          >
            Next
          </button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4">Generated Intros</h2>
        <div className="space-y-4 max-h-96 overflow-y-auto p-2 scrollbar-thin scrollbar-thumb-primary-500 scrollbar-track-gray-200 rounded-lg">
          {generatedIntros.map((intro, index) => (
            <div key={index} className="flex justify-between items-center border p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300">
              <span className="text-gray-700">{intro}</span>
              <button
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-700"
                onClick={() => handleSelectIntro(intro)}
              >
                Select
              </button>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default StepIntro;
