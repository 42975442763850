import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegImage } from 'react-icons/fa';
import { MdContentPaste } from "react-icons/md";
import { AiOutlineRetweet } from 'react-icons/ai';
import { GrBlog } from "react-icons/gr";
import { IoCartOutline, IoShareSocialOutline } from "react-icons/io5";

const FeatureDropdown = ({ isOpen }) => {
    if (!isOpen) return null;

    return (
        <div className="absolute left-20 w-screen -mt-4">
            <div className=" max-w-5xl p-8 transform -translate-x-1/2 left-1/2">
                <div className='bg-white p-8 border border-gray-50 rounded-3xl shadow-xl grid grid-cols-3 gap-4 '>
                    <Link
                        className="flex items-start px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                        to="/article-generator"
                    >
                        <MdContentPaste className="text-primary-600 w-10 h-10 -mt-1 mr-2" />
                        <div>
                            <div className="font-semibold">Article Generator</div>
                            <div className="text-sm mt-1">Turn a title and an outline into a fully SEO-optimized and long article with this AI editor.</div>
                        </div>
                    </Link>
                    <Link
                        className="flex items-start px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                        to="/blog-post-generator"
                    >
                        <GrBlog className="text-primary-600 w-9 h-9 mr-2" />
                        <div>
                            <div className="font-semibold">Blog Post Writer</div>
                            <div className="text-sm mt-1">Quickly write quality blog posts with our drag and drop article writer.</div>
                        </div>
                    </Link>
                    <Link
                        className="flex items-start px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                        to="/rewrite-conetent-generator"
                    >
                        <AiOutlineRetweet className="text-primary-600 w-12 h-12 -mt-2 mr-2" />
                        <div>
                            <div className="font-semibold">Content Rewriter</div>
                            <div className="text-sm mt-1">Rewrite long-form content or any text in seconds to make it unique and plagiarism-free.</div>
                        </div>
                    </Link>
                    <Link
                        className="flex items-start px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                        to="/image-generator"
                    >
                        <FaRegImage className="text-primary-600 w-10 h-10 -mt-1 mr-2" />
                        <div>
                            <div className="font-semibold">Image Generator</div>
                            <div className="text-sm mt-1">Create stunning images from text descriptions with our AI-powered image generator.</div>
                        </div>
                    </Link>
                    <Link
                        className="flex items-start px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                        to="/product-description-generator"
                    >
                        <IoCartOutline className="text-primary-600 w-10 h-10 -mt-1 mr-2" />
                        <div>
                            <div className="font-semibold">Ecommerce Product Description</div>
                            <div className="text-sm mt-1">Generate compelling product descriptions for your ecommerce store.</div>
                        </div>
                    </Link>
                    <Link
                        className="flex items-start px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                        to="/social-media-post-generator"
                    >
                        <IoShareSocialOutline className="text-primary-600 w-10 h-10 -mt-1 mr-2" />
                        <div>
                            <div className="font-semibold">Social Media Post</div>
                            <div className="text-sm mt-1">Create engaging social media posts to captivate your audience.</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FeatureDropdown;
