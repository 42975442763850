import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import PageHeader from '../../Components/PageHeader';
import StepNavigation from './StepNavigation';
import Modal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import "../../../style/scroll.css";
import axiosInstance from '../../../Common/axiosInstance';

const StepOutline = ({ nextStep, prevStep, handleChange, values, currentStep, steps }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [generatedOutlines, setGeneratedOutlines] = useState([]);
  const [selectedOutlines, setSelectedOutlines] = useState(values.outline || []);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('Generating outlines, please wait...');
  const [inputFields, setInputFields] = useState(values.outline && values.outline.map(outline => ({ value: outline, error: false })) || [{ value: '', error: false }]);

  const navigate = useNavigate();

  useEffect(() => {
    const savedOutlines = localStorage.getItem('generatedOutlines');
    if (savedOutlines) {
      try {
        setGeneratedOutlines(JSON.parse(savedOutlines));
      } catch (e) {
        console.error('Error parsing saved outlines from localStorage:', e);
      }
    }
  }, []);

  console.log('generatedOutlines', generatedOutlines);
  

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 2;
        });
      }, 100);
      const response = await axiosInstance.post('/connect/generate-outlines/', {
        language: values.language,
        keyword: values.keyword,
        creativity: values.creativity,
        description: values.description,
        title: values.title,
        intro: values.intro
      });

      const getoutlines = response.data;
      const outlines = getoutlines.blog_outlines;
      
      setGeneratedOutlines(outlines);
      localStorage.setItem('generatedOutlines', JSON.stringify(outlines));
      setIsModalOpen(true);
      setProgress(0);
    } catch (error) {
      console.error('Failed to generate outlines', error);
    }
    setIsLoading(false);
  };

  const handleSelectOutline = (outline) => {
    setSelectedOutlines(prevOutlines => {
      if (prevOutlines.includes(outline)) {
        return prevOutlines.filter(item => item !== outline);
      } else {
        return [...prevOutlines, outline];
      }
    });
  };

  const handleRegenerate = () => {
    setGeneratedOutlines([]);
    localStorage.removeItem('generatedOutlines');
    handleGenerate();
  };

  const handleAddField = () => {
    setInputFields([...inputFields, { value: '', error: false }]);
  };

  const handleRemoveField = (index) => {
    const fields = [...inputFields];
    fields.splice(index, 1);
    setInputFields(fields);
    handleChange('outline', fields.map(field => field.value));
  };

  const handleInputChange = (index, event) => {
    const fields = [...inputFields];
    const value = event.target.value;
    if (value.length <= 200) {
      fields[index].value = value;
      fields[index].error = false;
    } else {
      fields[index].error = true;
    }
    setInputFields(fields);
    handleChange('outline', fields.map(field => field.value));
  };

  const saveSelections = () => {
    setInputFields(selectedOutlines.map(outline => ({ value: outline, error: false })));
    setIsModalOpen(false);
    handleChange('outline', selectedOutlines);
  };

  const onSubmitCreate = async () => {
    if (selectedOutlines.length === 0) {
      setError(true);
      return;
    }

    setLoadingMessage('Generating blog, please wait...');
    setError(false);
    setProgress(0);
    setIsLoading(true);
    try {
      const fakeProgress = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 100) {
            clearInterval(fakeProgress);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 100);

      const response = await axiosInstance.post('/connect/generate-content/', {
        language: values.language,
        keyword: values.keyword,
        creativity: values.creativity,
        description: values.description,
        title: values.title,
        intro: values.intro,
        outline: selectedOutlines
      });
      
      if(response) { 
        localStorage.removeItem('blogs');
        navigate('/generated-blog', { state: { id: response.data.id } })
      }

    } catch (error) {
      console.error('Failed to generate content', error);
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full md:max-w-xl pb-10 md:pb-40 px-2 md:px-0">
      {isLoading && <Loading progress={progress} message={loadingMessage} />}
      <PageHeader title="Blog Post Generator" subtitle="Generate an outline (subheadings) for your post" />
      <StepNavigation currentStep={currentStep} steps={steps} />
      <div className="bg-white rounded-b-lg shadow-lg p-8">
        <div className="grid grid-cols-1 gap-4 mt-4">
          <div>
            <label className="block text-gray-700 font-semibold mb-2">Outline</label>
            {inputFields.map((inputField, index) => (
              <div key={index} className="flex items-center space-x-2 mb-2">
                <input
                  type="text"
                  className={`w-full p-2 text-gray-600 border ${inputField.error ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                  value={inputField.value}
                  onChange={event => handleInputChange(index, event)}
                  placeholder="Enter outline subheading (max. 200 characters)"
                />
                {inputField.error && (
                  <small className="text-red-500">
                    Must be less than 200 characters
                  </small>
                )}
                {index !== inputFields.length - 1 ? (
                  <button
                    type="button"
                    className="px-3 py-1"
                    onClick={() => handleRemoveField(index)}
                  >
                    <FaCircleMinus size={25} className="text-red-500 hover:text-red-600" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="px-3 py-1"
                    onClick={handleAddField}
                  >
                    <FaCirclePlus size={25} className="text-green-500 hover:text-green-600" />
                  </button>
                )}
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center space-y-4 mb-4">
            <button
              className="mt-3 md:mt-4 mr-4 px-1 md:px-1 py-2 bg-white border border-primary-600 text-primary-600 hover:bg-gradient-to-r hover:from-primary-500 hover:to-primary-700 hover:text-white rounded-lg"
              onClick={handleRegenerate}
            >
              {generatedOutlines.length > 0 ? 'Regenerate Outlines' : 'Generate Outlines'}
            </button>
            {generatedOutlines.length > 0 && (
              <button
                className="md:mt-2 px-2 md:px-7 py-2 bg-gray-400 border border-gray-400 text-white rounded-lg hover:bg-gray-600"
                onClick={() => setIsModalOpen(true)}
              >
                View Outlines
              </button>
            )}
          </div>
        </div>
        {error && (
          <div className="text-red-500 mt-2">
            <small>Outline is required.</small>
          </div>
        )}
        <div className="mt-6 flex justify-between">
          <button
            className="px-4 py-2 bg-gradient-to-r from-gray-500 to-gray-700 text-white rounded-lg"
            onClick={prevStep}
          >
            Previous
          </button>
          <button
            className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg"
            onClick={onSubmitCreate}
            disabled={inputFields.some(field => field.error || field.value.trim() === '')}
          >
            Generate Blog
          </button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-xl font-bold mb-4">Generated Outlines</h2>
        <div className="space-y-4 max-h-96 overflow-y-auto p-2 scrollbar-thin scrollbar-thumb-primary-500 scrollbar-track-gray-200 rounded-lg">
          {generatedOutlines.map((outline, index) => (
            <div
              key={index}
              className={`flex justify-between items-center border p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 ${selectedOutlines.includes(outline) ? 'bg-primary-100' : ''}`}
            >
              <span className="text-gray-700">{outline}</span>
              <button
                className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-700"
                onClick={() => handleSelectOutline(outline)}
              >
                {selectedOutlines.includes(outline) ? 'Deselect' : 'Select'}
              </button>
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            className="px-4 py-2 bg-gradient-to-r from-primary-500 to-primary-700 text-white rounded-lg"
            onClick={saveSelections}
          >
            Save Selections
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default StepOutline;
