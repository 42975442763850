import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFileInvoice } from 'react-icons/fa';
import { MdArticle, MdImage } from 'react-icons/md'; // Material Design icons
import { SiBloglovin, SiShopify } from 'react-icons/si'; // Simple Icons
import { AiOutlineRetweet, AiOutlineShareAlt } from 'react-icons/ai';
import DashboardCard from './Components/DashboardCard';
import { useDispatch } from 'react-redux';

const DashboardHome = () => { 

  const dummyData = [
    {
      title: "Article Generator",
      description: "Turn a title and an outline into a fully SEO-optimized and long article with this AI editor.",
      icon: <MdArticle size={34} className="text-primary-500" />,
      link: "/article-generator"
    },
    {
      title: "Blog Post Writer",
      description: "Quickly write quality blog posts with our drag and drop article writer.",
      icon: <SiBloglovin size={34} className="text-primary-500" />,
      link: "/blog-post-generator"
    },
    {
      title: "Content Rewriter",
      description: "Rewrite long-form content or any text in seconds to make it unique and plagiarism-free.",
      icon: <AiOutlineRetweet size={34} className="text-primary-500" />,
      link: "/rewrite-conetent-generator"
    },
    {
      title: "Image Generator",
      description: "Create stunning images from text descriptions with our AI-powered image generator.",
      icon: <MdImage size={34} className="text-primary-500" />,
      link: "/image-generator"
    },
    {
      title: "Ecommerce Product Description",
      description: "Generate compelling product descriptions for your ecommerce store.",
      icon: <SiShopify size={34} className="text-primary-500" />,
      link: "/product-description-generator"
    },
    {
      title: "Social Media Post",
      description: "Create engaging social media posts to captivate your audience.",
      icon: <AiOutlineShareAlt size={34} className="text-primary-500" />,
      link: "/social-media-post-generator"
    }  
  ];
 

  return (
    <div className="flex">

      <div className="flex-1">
        <main className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 mb-6 ">
            <div>
              <h1 className="text-3xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Most Popular Tools</h1>
              <p className="text-gray-500 dark:text-gray-300">
                These are the most popular tools and a good place to start. Give them a try!
              </p>
            </div> 
          </div>

          {/* <Tabs /> */}
          <div className="grid grid-cols-1 mt-5">
            <DashboardCard data={dummyData} />
          </div> 

        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default DashboardHome;
