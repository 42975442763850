import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt, FaUsers } from 'react-icons/fa';
import ContactForm from './ContactForm';
import { motion } from 'framer-motion';

const ContactInfo = () => {
  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-16">
      <div className="container px-8 mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <div className="mb-6">
              <h3 className="inline-block px-3 py-1 mb-4 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">
                Connect with Us
              </h3>
              <h1 className="text-4xl font-semibold text-gray-800 capitalize dark:text-white">
                Transform Your Content Creation Process
              </h1>
              <p className="mt-6 text-gray-700 dark:text-gray-400">
                Whether you have questions about our AI-driven solutions, need support with talent acquisition, or want to explore seamless onboarding and skills development tools. We are here to help.
                </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
              <div className="flex items-start my-6">
                <FaMapMarkerAlt className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Office location</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">16122 Collins Street, Melbourne, Australia</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaEnvelope className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Send a message</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">info@connectaibox.com</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">sales@connectaibox.com</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaPhoneAlt className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Call us directly</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">1-800-222-000</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">1-800-222-002</p>
                </div>
              </div>
              <div className="flex items-start my-6">
                <FaUsers className="w-8 h-8 text-primary-600 dark:text-primary-400" />
                <div className="ml-4">
                  <h4 className="text-lg font-medium text-gray-900 dark:text-gray-200">Join our team</h4>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">hire@connectaibox.com</p>
                  <p className="mt-2 text-gray-700 dark:text-gray-400">hr@connectaibox.com</p>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <ContactForm />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
